import competition from './activites/competition.webp';
import event from './activites/event.webp';
import publicEvent from './events/public.svg';
import privateEvent from './events/private.svg';
import trip from './activites/trip.webp';
import jazzcash from './logo/JazzCash.png';
import NotAllowed from './animations/not-allowed.json';
import UnderMaintenance from './animations/under-maintenance.json';
import Banner from './banner/Banner.png';
import CreateEventBanner from './events/create-event.jpeg';
import FestiveQR from './svgs/festiveQR.svg';
import FestiveBarcode from './svgs/festive-barcode.svg';
import creditCard from './svgs/credit-card.svg';
import alfalah from './logo/alfalah.png';
import festive from './logo/festive.png';

export const images = {
  eventType: {
    public: publicEvent,
    private: privateEvent,
  },
  activities: {
    competition,
    event,
    trip,
  },
  logos: {
    jazzcash,
    alfalah,
    festive,
  },
  animation: {
    NotAllowed,
    UnderMaintenance,
  },
  banner: {
    CreateEventBanner,
    Banner,
  },
  svg: {
    FestiveQR,
    FestiveBarcode,
    creditCard,
  }
};
