import { AnimatePresence } from 'framer-motion';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import { UserProvider } from './context/UserContext';
import { Loader } from 'rsuite';
import ArticleLayout from './layouts/ArticleLayout';
import 'rsuite/dist/rsuite.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-multi-carousel/lib/styles.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-quill/dist/quill.snow.css';
import 'video-react/dist/video-react.css';
import './index.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorLayout, logErrorToService } from 'pages/Error';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ErrorBoundary FallbackComponent={ErrorLayout} onError={logErrorToService}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <UserProvider>
        <BrowserRouter>
          <Suspense
            fallback={
              <ArticleLayout footer={false}>
                <Loader
                  size='lg'
                  className='flex items-center justify-center h-screen mx-auto text-main'
                />
              </ArticleLayout>
            }
          >
            <AnimatePresence mode='wait' initial>
              <AppRoutes />
            </AnimatePresence>
          </Suspense>
        </BrowserRouter>
      </UserProvider>
    </GoogleOAuthProvider>
  </ErrorBoundary>

  // </React.StrictMode>
);
