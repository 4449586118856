export const getDayAndMonth = (date) => {
  return new Date(date).toLocaleString('default', {
    day: 'numeric',
    month: 'short',
  });
};

export const getDayAndMonthAndYear = (date) => {
  return new Date(date).toLocaleString('default', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

export const getDayAndMonthAndYearAndTime = (date) => {
  return new Date(date).toLocaleString('default', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
};


export const getTime = (date) => {
  return new Date(date).toLocaleString('default', {
    hour: 'numeric',
    minute: 'numeric',
  });
};

export const getDayDifference = (date1, date2) => {
  date1 = new Date(date1);
  date2 = new Date(date2);
  return Math.floor((date1 - date2) / (1000 * 60 * 60 * 24));
};

export const getYear = (date) => {
  return new Date(date).getFullYear();
};


export class DateHelper {
  static format(date: string | Date, formatter?: Intl.DateTimeFormatOptions): string {
    return new Date(date).toLocaleDateString('en-US', {
      day: formatter?.day || 'numeric',
      month: formatter?.month || 'short',
      year: formatter?.year || 'numeric',
      ...formatter
    });
  }
}