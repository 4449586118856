import { IoMdLogOut } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton, Nav, Navbar, Popover, Whisper } from 'rsuite';
import RoutesPath from '../../routes/types';
import FestiveLabLogo from '../../assets/logo/festive.png';
import { RiMenuFoldFill } from 'react-icons/ri';
import { RxAvatar } from 'react-icons/rx';
import Button from '../../components/Common/Button';
import { useUser } from '../../context/UserContext';

const Header = () => {
  const navigate = useNavigate();

  const { isVerified, logout } = useUser();

  return (
    <Navbar className='sticky top-0 z-50 flex items-center w-full h-12 border-b-2 md:h-20 py-auto border-main bg-white/90 backdrop-blur-md'>
      <div className='flex items-center justify-between flex-grow px-10 mx-auto md:px-20'>
        <div
          className='flex items-center cursor-pointer'
          onClick={() => navigate(RoutesPath.home)}
        >
          <img
            src={FestiveLabLogo}
            className='w-5 h-5 md:h-10 md:w-10'
            alt=''
          />
          <Navbar.Brand className='z-50 flex gap-2 pl-2 pr-2 font-bold cursor-pointer text-md md:text-xl'>
            FestiveLabs
          </Navbar.Brand>
          {/* <span className="px-2 py-1 border-2 rounded border-main text-main">
            Beta
          </span> */}
        </div>
        <Nav pullRight className='z-50 items-center hidden lg:flex'>
          {isVerified() ? (
            <>
              {/* <Nav.Item
                onClick={() =>
                  navigate(RoutesPath.organizeEvent + RoutesPath.dashboard)
                }
                className="z-50 h-10 text-base transition rounded-full active:border-main text-body-color active:bg-blue-500 hover:text-white"
              > */}
              <Link
                to={`${RoutesPath.organizeEvent}${RoutesPath.dashboard}`}
                className='z-50 rounded-[3rem] border border-black/40 px-4 py-1.5 no-underline hover:border-main hover:bg-black/5 hover:no-underline'
              >
                Organize
              </Link>
              {/* </Nav.Item> */}
              <Nav.Item onClick={() => navigate('/profile')}>
                <Whisper
                  placement='bottom'
                  trigger='hover'
                  followCursor
                  controlId='control-id-hover2'
                  speaker={<Popover>Profile</Popover>}
                >
                  <IconButton icon={<RxAvatar size={30} />} />

                  {/* <img
                    src="https://source.unsplash.com/jmURdhtm7Ng/120x120"
                    className="z-50 w-12 h-12 align-middle border-8 border-white rounded-full shadow dark:shadow-xl dark:border-gray-800"
                    alt=""
                  /> */}
                </Whisper>
              </Nav.Item>
              <Nav.Item
                onClick={() => {
                  logout();
                  navigate(RoutesPath.auth, { state: { isLogin: true } });
                }}
              >
                <Whisper
                  placement='bottom'
                  trigger='hover'
                  followCursor
                  controlId='control-id-hover'
                  speaker={<Popover>Logout</Popover>}
                >
                  <div>
                    <IoMdLogOut size={30} />
                  </div>
                </Whisper>
              </Nav.Item>
            </>
          ) : (
            <>
              <Nav.Item
                onClick={() =>
                  navigate(RoutesPath.auth, { state: { isLogin: true } })
                }
              >
                Login
              </Nav.Item>
            </>
          )}
        </Nav>
        <Nav className='lg:hidden'>
          {/* <Nav.Item className=""> */}
          <Whisper
            placement='bottomEnd'
            trigger='active'
            controlId='control-id-active'
            speaker={
              <Popover>
                <div className='flex flex-col'>
                  {isVerified() ? (
                    <>
                      <Nav.Item
                        onClick={() =>
                          navigate(
                            RoutesPath.organizeEvent + RoutesPath.dashboard
                          )
                        }
                        className='h-12 border-y hover:shadow-all'
                      >
                        <Button
                          text='Organize Events'
                          className='text-sm rounded-none md:text-base'
                        />
                      </Nav.Item>
                      <Nav.Item className='h-10 hover:shadow-all'>
                        <Link to={'/profile'} className='flex-grow'>
                          <div className='flex items-center'>
                            <RxAvatar size={22} />
                            {/* <img
                              src="https://source.unsplash.com/jmURdhtm7Ng/120x120"
                              className="z-50 align-middle border-2 border-white rounded-full shadow dark:shadow-xl dark:border-gray-800 h-7 w-7"
                              alt=""
                            /> */}
                            <p className='ml-2'>Profile</p>
                          </div>
                        </Link>
                      </Nav.Item>
                      <Nav.Item
                        className='h-10 hover:shadow-all'
                        onClick={() => {
                          logout();
                          navigate(RoutesPath.auth, {
                            state: { isLogin: true },
                          });
                        }}
                      >
                        <IoMdLogOut size={24} />
                        <p className='ml-2'>Logout</p>
                      </Nav.Item>
                    </>
                  ) : (
                    <>
                      <Nav.Item
                        className='h-10 hover:shadow-all'
                        onClick={() =>
                          navigate(RoutesPath.auth, {
                            state: { isLogin: true },
                          })
                        }
                      >
                        Login
                      </Nav.Item>
                    </>
                  )}
                </div>
              </Popover>
            }
          >
            <IconButton
              icon={<RiMenuFoldFill className='text-sm md:text-md' />}
              circle
            />
          </Whisper>
          {/* </Nav.Item> */}
        </Nav>
      </div>
    </Navbar>
  );
};

export default Header;
