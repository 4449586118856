import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import errorAnimation from 'assets/animations/error.json';
import Lottie from 'react-lottie-player';

export function logErrorToService(error: Error, info: React.ErrorInfo) {
  console.log(error, info);
}

export function ErrorLayout({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div className='flex flex-col items-center justify-center h-screen max-w-lg gap-4 mx-auto'>
      <Lottie animationData={errorAnimation} play loop className='w-96 h-96' />
      <h1 className='text-xl font-bold'>OOPS!!!</h1>
      <p>{error.message}</p>
      <button className='w-full py-2 text-white rounded-lg bg-main' onClick={resetErrorBoundary}>Reload Page</button>
    </div>
  );
}