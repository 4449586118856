import React from 'react';
import Lottie from 'react-lottie-player';

interface LottieAnimationProps {
  animation: any;
  title?: string;
  width?: number;
  height?: number;
  textClass?: string;
  className?: string;
  loop?: boolean;
  speed?: number;
}

const LottieAnimation: React.FC<LottieAnimationProps> = ({
  animation,
  title = 'Coming Soon',
  width = 400,
  height = 400,
  textClass,
  className,
  loop = true,
  speed = 1,
}) => {
  return (
    <div
      className={`mx-auto flex flex-col justify-center items-center ${
        !textClass && 'my-20'
      }`}
    >
      <Lottie
        loop={loop}
        animationData={animation}
        play
        className={className}
        style={{ width, height }}
        speed={speed}
      />
      <p className={textClass ? textClass : 'font-bold text-3xl uppercase'}>
        {title.split('<br />').map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
      </p>
    </div>
  );
};

export default LottieAnimation;
