import { useUser } from 'context/UserContext';
import { Toast } from 'helper/toast';
import { Coupon, CustomerTicket, IJazzCashMobileOrderRequest, PaymentMethodCodes, TicketTemplate } from 'models';
import { initiateMobileWalletRequest } from 'paymentMethods/jazzCash';
import { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Modal } from 'rsuite';
import SecureLS from 'secure-ls';

interface UserFieldProps {
	phoneNumber: string;
	cnic: string;
}

interface JazzCashModalFieldsProps {
	open: boolean;
	onClose: () => void;
	eventId: string;
}

export const JazzCashModalFields: React.FC<JazzCashModalFieldsProps> = ({ open, eventId, onClose }) => {
	const [isOpen, setIsOpen] = useState<boolean>(open);

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	const [user, setUser] = useState<UserFieldProps>({ phoneNumber: '', cnic: '' });

	const [loader, setLoader] = useState<boolean>(false);
	const [captcha, setCaptcha] = useState<string | null>(null);

	const ls = new SecureLS();

	const [cart] = useState<
		{ ticket: CustomerTicket; template: TicketTemplate; coupon?: Coupon }[]
	>(ls.get(`cart_${eventId}`) || []);

	const { API } = useUser();

	const navigate = useNavigate();

	const handleJazzCashPayment = async () => {
		try {
			setLoader(true);

			if (!captcha) return Toast.warning('Please verify captcha');
			if (user.phoneNumber.length !== 11) return Toast.warning('Invalid Phone Number');
			if (user.cnic.length !== 6) return Toast.warning('Invalid CNIC');

			const { data } = await API.post('/ticket/buy', {
				eventId,
				bank: PaymentMethodCodes.JAZZCASH,
				recaptchaValue: captcha,
				customerTicketDetails: cart.map((cartTicket) => cartTicket.ticket),
				cnic: user.cnic,
				phoneNo: user.phoneNumber,
			});

			const { paymentGatewayRequest }: { paymentGatewayRequest: IJazzCashMobileOrderRequest } = data.dto;

			const mobileWalletResponse = await initiateMobileWalletRequest(paymentGatewayRequest.pp_BillReference, paymentGatewayRequest.ppmpf_1, paymentGatewayRequest.ppmpf_2, paymentGatewayRequest.pp_TxnDateTime, paymentGatewayRequest.pp_TxnExpiryDateTime, paymentGatewayRequest.pp_Amount, paymentGatewayRequest.pp_MobileNumber, paymentGatewayRequest.pp_CNIC);

			// if (mobileWalletResponse) {
			// 	Toast.success('Payment Successful');
			// 	navigate(`/checkout/${PaymentMethodCodes.JAZZCASH}?status=success`);
			// }
			switch (mobileWalletResponse.pp_ResponseCode) {
				// Payment Successful
				case '000':
				case '121':
					navigate(`/checkout/${PaymentMethodCodes.JAZZCASH}?status=success`);
					break;

				// Payment Pending
				case '124':
				case '157':
				case '156':
					navigate(`/checkout/${PaymentMethodCodes.JAZZCASH}?status=pending`);
					break;

				// Payment Failed
				default:
					navigate(`/checkout/${PaymentMethodCodes.JAZZCASH}?status=failed&error=${mobileWalletResponse.pp_ResponseMessage}`);
					break;
			}
			onClose();
		} catch (error) {
			const { message, response } = error;
			if (message) Toast.error(message);
			if (response) Toast.error(response.data.message);
		} finally {
			setLoader(false);
		}
	};

	return (
		<Modal open={isOpen} onClose={onClose}>
			<Modal.Header>
				<Modal.Title>Pay with JazzCash</Modal.Title>
			</Modal.Header>
			<Modal.Body className='flex flex-col gap-4'>
				<div className='flex items-start justify-center w-full gap-2 p-2 mx-auto font-medium text-center text-yellow-500 rounded shadow bg-[rgb(255,252,216)]'>
					<BsFillInfoCircleFill />
					<i>For ticket transaction, kindly open the JazzCash app on your mobile after click on pay</i>
				</div>
				<form className='flex flex-col gap-4'>
					<div className='flex flex-col gap-2'>
						<label htmlFor="phoneNumber">Phone Number</label>
						<Input
							type="number"
							id="phoneNumber"
							name="phoneNumber"
							pattern='[0-9]{11}'
							required
							value={user.phoneNumber}
							placeholder='03XXXXXXXXX'
							onChange={(e) => e.length <= 11 && setUser((prev) => ({ ...prev, phoneNumber: e }))}
						/>
					</div>
					<div className='flex flex-col gap-2'>
						<label htmlFor="cnic">Enter last 6 digits of CNIC</label>
						<Input
							type="number"
							id="cnic"
							name="cnic"
							pattern='[0-9]{6}'
							required
							value={user.cnic}
							placeholder='XXXXXX'
							onChange={(e) => e.length <= 6 && setUser((prev) => ({ ...prev, cnic: e }))}
						/>
					</div>
					<ReCAPTCHA
						className='flex justify-center'
						sitekey={process.env.REACT_APP_RECAPTCHA_API_SITE_KEY}
						onChange={(v) => setCaptcha(v)}
					/>
				</form>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={onClose}>Cancel</Button>
				<Button onClick={handleJazzCashPayment} appearance='primary' className='px-6' loading={loader}>Pay</Button>
			</Modal.Footer>
		</Modal>
	);
};