import {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Form,
  IconButton,
  InputGroup,
  Modal,
  SelectPicker,
} from 'rsuite';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { AiOutlineSave, AiOutlineShoppingCart } from 'react-icons/ai';
import { RiCoupon2Line } from 'react-icons/ri';
import { Toast } from 'helper/toast';
import EventCardSmall from 'components/Event/EventCardSmall';
import parse from 'html-react-parser';
import Lottie from 'react-lottie-player';
import selectLottie from '../../assets/animations/select-item.json';
import { CustomerTicket, TicketTemplate, Coupon } from 'models';
import CustomerTicketPreview from '../../components/UI/CustomerTicketPreview/CustomerTicketPreview';
import Swal from 'sweetalert2';
import SecureLS from 'secure-ls';
import { Event } from 'types/Event';
import ReCAPTCHA from 'react-google-recaptcha';
import TicketCardList from 'components/UI/TicketCardList/TicketCardList';

const MAX_CHECKOUT_TICKETS = 5;

const TicketDetails = ({ ticket }: { ticket: TicketTemplate }) => (
  <div className='flex flex-col gap-2 p-4 border border-gray-300 rounded-xl'>
    <div className='text-lg font-bold'>Pass For: {ticket.title}</div>
    {!!ticket.earlyBirdDiscount && (
      <div className='flex justify-between'>
        <div className='font-semibold text-neutral-400'>
          Early Bird Discount
        </div>
        <div>{ticket.earlyBirdDiscount}</div>
      </div>
    )}
    <div className='flex justify-between'>
      <div className='font-semibold text-neutral-400'>Price</div>
      <div>
        <span className={ticket.earlyBirdDiscount ? 'line-through' : ''}>
          {ticket.price + (ticket.earlyBirdDiscount || 0)}
        </span>
        {!!ticket.earlyBirdDiscount && (
          <>
            &nbsp;
            <span>{ticket.price}</span>
          </>
        )}
      </div>
    </div>
    <div className='flex justify-between'>
      <div className='font-semibold text-neutral-400'>Scans Allowed</div>
      <div>{ticket.scansAllowed}</div>
    </div>
    {/* <div className='flex justify-between'>
      <div className='font-semibold text-neutral-400'>Pass Type</div>
      <div>{ticket.ticketType}</div>
    </div> */}
    <div className='font-semibold text-neutral-400'>Description</div>
    <div className='p-4 bg-gray-100 border-2 border-dashed rounded-md'>
      {parse(ticket.description ?? '<p>No Description Available</p>')}
    </div>
  </div>
);

const BuyEventPass = ({ onProceed }: { onProceed: () => void }) => {
  const { API } = useUser();

  const ls = new SecureLS();

  const location = useLocation();
  const navigate = useNavigate();
  const eventId = useMemo(() => location.pathname.split('/')[2], [location]);

  const [event, setEvent] = useState<Event>(null);
  const [loadingEvent, setLoadingEvent] = useState<boolean>(false);

  const [ticketTypes, setTicketTypes] = useState<TicketTemplate[]>([]);
  const [loadingTickets, setLoadingTickets] = useState<boolean>(false);
  const [selectedTicket, setSelectedTicket] = useState<TicketTemplate>(null);

  const [cart, _setCart] = useState<
    { ticket: CustomerTicket; template: TicketTemplate; coupon?: Coupon }[]
  >(/* ls.get(`cart_${eventId}`) || */ []);
  const totalDiscount = useMemo(
    () =>
      cart.reduce((total, ticket) => total + (ticket.coupon?.discount || 0), 0),
    [cart]
  );
  const totalAmount = useMemo(
    () =>
      cart.reduce(
        (total, ticket) =>
          total + ticket.template.price - (ticket.coupon?.discount || 0),
        0
      ),
    [cart]
  );

  // Modal States
  const [ticketModal, setTicketModal] = useState<boolean>(false);
  const [verifyingCoupon, setVerifyingCoupon] = useState<boolean>(false); //TODO: show coupon verifying
  const [deleteTicket, setDeleteTicket] = useState<{
    ticket: CustomerTicket;
    template: TicketTemplate;
    coupon?: Coupon;
  }>(null);
  const [editedTicket, setEditedTicket] = useState<{
    ticket: CustomerTicket;
    template: TicketTemplate;
    coupon?: Coupon;
  }>(null);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, boolean>
  >({});

  //in case of free event only
  const [captcha, setCaptcha] = useState<string | null>();
  const [freeTicketLoading, setFreeTicketLoading] = useState<boolean>(false);

  const ticketsRef = useRef(null);

  const setCart = useCallback(
    (
      data: {
        ticket: CustomerTicket;
        template: TicketTemplate;
        coupon?: Coupon;
      }[]
    ) => {
      ls.set(`cart_${eventId}`, data);
      _setCart(data);
    },
    [_setCart]
  );

  useEffect(() => {
    const fetchTicketTypes = async () => {
      try {
        setLoadingTickets(true);

        const { data: res } = await API.get(
          `/ticketTemplate/event/A?eventId=${eventId}`
        );
        setTicketTypes(res.dto);
      } catch {
        Toast.error('Error getting ticket. Please try again later.');
      } finally {
        setLoadingTickets(false);
      }
    };

    const fetchEvent = async () => {
      try {
        setLoadingEvent(true);
        const { data: res } = await API.get(`/event/${eventId}`);
        setEvent(res.dto);
      } catch {
        Toast.error('Error loading event details. Please try again later.');
      } finally {
        setLoadingEvent(false);
      }
    };

    fetchTicketTypes();
    fetchEvent();
  }, []);

  const onTicketChange = (index: number) => {
    setSelectedTicket(ticketTypes[index]);
  };

  const openTicketForm = () => {
    if (cart.length === MAX_CHECKOUT_TICKETS) {
      return Swal.fire(
        'Ticket Limit Reached',
        'You can only buy 5 tickets at a time.',
        'warning'
      );
    }

    if (
      selectedTicket.purchasesLeft < 0 ||
      selectedTicket.purchasesLeft >
        cart.filter((t) => t.template._id === selectedTicket._id).length
    ) {
      setTicketModal(true);
    } else
      Swal.fire(
        'Ticket Limit Reached',
        'You cannot buy more tickets of this type.',
        'warning'
      );
  };

  const createCustomerTicket = (
    coupon: string,
    values: any,
    editMode?: boolean
  ): CustomerTicket => ({
    ticketTemplateId: editMode ? editedTicket.template._id : selectedTicket._id,
    couponCode: coupon,
    customerTicketDetails: Object.entries(values).map(([k, v]) => ({
      name: k,
      value: v.toString(),
    })),
  });

  const addTicketToCart = async (
    _check: boolean,
    e: FormEvent<HTMLFormElement>
  ) => {
    const formData = new FormData(e.target as HTMLFormElement);
    const values = Object.fromEntries(formData.entries());

    const coupon = values.coupon?.toString();
    delete values.coupon;

    if (coupon) {
      try {
        setVerifyingCoupon(true);
        const couponRes = await API.post('/coupon/verify', {
          eventId,
          couponCode: coupon,
          ticketTemplateId: selectedTicket._id,
        });

        const couponModel: Coupon = couponRes.data.dto;

        setCart([
          ...cart,
          {
            ticket: createCustomerTicket(coupon, values),
            template: selectedTicket,
            coupon: couponModel,
          },
        ]);

        setTicketModal(false);
      } catch (e) {
        /* */
      } finally {
        setVerifyingCoupon(false);
      }
    } else {
      setCart([
        ...cart,
        {
          ticket: createCustomerTicket(coupon, values),
          template: selectedTicket,
        },
      ]);
      setTicketModal(false);
    }
  };

  const saveEditedTicket = async (
    _check: boolean,
    e: FormEvent<HTMLFormElement>
  ) => {
    const formData = new FormData(e.target as HTMLFormElement);
    const values = Object.fromEntries(formData.entries());

    const coupon = values.coupon.toString();
    delete values.coupon;

    if (coupon) {
      try {
        setVerifyingCoupon(true);
        const couponRes = await API.post('/coupon/verify', {
          eventId,
          couponCode: coupon,
          ticketTemplateId: editedTicket.template._id,
        });

        const couponModel: Coupon = couponRes.data.dto;
        setCart(
          cart.map((t) =>
            t !== editedTicket
              ? t
              : {
                  ticket: createCustomerTicket(coupon, values, true),
                  template: editedTicket.template,
                  coupon: couponModel,
                }
          )
        );
        setEditedTicket(null);
      } catch (e) {
        /* */
      } finally {
        setVerifyingCoupon(false);
      }
    } else {
      setCart(
        cart.map((t) =>
          t !== editedTicket
            ? t
            : {
                ticket: createCustomerTicket(coupon, values, true),
                template: editedTicket.template,
              }
        )
      );
      setEditedTicket(null);
    }
  };

  const getFreeTickets = async () => {
    // call free api
    try {
      setFreeTicketLoading(true);
      const { data } = await API.post('/ticket/free', {
        eventId,
        recaptchaValue: captcha,
        customerTicketDetails: cart.map((cartTicket) => cartTicket.ticket),
      });

      Toast.success(data.message);
      ls.remove(`cart_${eventId}`);
      navigate('/checkout/ALFALAH');
    } catch (e) {
      Toast.error(e);
    } finally {
      setFreeTicketLoading(false);
    }
  };

  return (
    <div className='flex flex-wrap justify-center gap-6 pb-40 mx-4 md:mx-8 md:pb-8'>
      <div className='flex-[6] md:p-8 sm:p-4 flex flex-col gap-4 bg-white rounded-lg shadow-md'>
        <p className='text-lg font-bold text-center md:text-2xl'>
          Choose A Ticket
        </p>
        <EventCardSmall
          event={event}
          variant='outline'
          showLocation
          loading={loadingEvent}
        />
        <div className='flex flex-wrap gap-4'>
          <SelectPicker
            placeholder='Select a Ticket'
            searchable={false}
            size='lg'
            className='flex-1'
            loading={loadingTickets}
            onChange={(i) => onTicketChange(i)}
            data={ticketTypes.map((ticket, index) => ({
              label: ticket.title,
              value: index,
            }))}
          />
          <Button
            appearance='primary'
            disabled={!selectedTicket}
            onClick={openTicketForm}
            className='!bg-main flex items-center !text-white'
          >
            <AiOutlineShoppingCart size={20} className='mr-4' />
            <span className='whitespace-nowrap'>Add to Cart</span>
          </Button>
        </div>
        {!selectedTicket && (
          <div className='flex flex-col items-center justify-center'>
            <div className='text-lg font-semibold'>
              Please select a Ticket to View its Details
            </div>
            <Lottie
              loop={true}
              speed={0.5}
              animationData={selectLottie}
              play
              style={{ width: 250, height: 250 }}
            />
          </div>
        )}
        {selectedTicket && <TicketDetails ticket={selectedTicket} />}
      </div>

      <div className='flex-[4] p-4 flex flex-col gap-4 bg-white rounded-lg shadow-md'>
        <h4
          ref={ticketsRef}
          className='text-lg font-bold text-center md:text-2xl'
        >
          Your Tickets
        </h4>

        <TicketCardList>
          {cart.map((t, i) => (
            <CustomerTicketPreview
              key={i}
              ticket={t.ticket}
              template={t.template}
              coupon={t.coupon}
              editable={true}
              onDelete={() => setDeleteTicket(t)}
              onEdit={() => setEditedTicket(t)}
            />
          ))}
        </TicketCardList>

        <div className='fixed bottom-0 left-0 right-0 w-full px-4 pt-2 pb-2 bg-white border-t md:static drop-shadow-xl md:drop-shadow-none md:border-0 md:pb-0'>
          <div className='flex flex-col md:gap-1'>
            {event?.entry === 'PAID' && (
              <>
                <div className='items-center justify-between hidden md:flex'>
                  <p className='font-medium'>Total Tickets:</p>
                  <div className='text-xl font-bold'>{cart.length}</div>
                </div>

                <div className='flex items-center justify-between'>
                  <p className='font-medium'>Total Discount:</p>
                  <div
                    className={`font-bold text-xl ${
                      totalDiscount > 0 ? 'text-green-400' : ''
                    }`}
                  >
                    {totalDiscount} Rs
                  </div>
                </div>
                <div className='flex items-center justify-between'>
                  <p className='font-medium'>Total Amount:</p>
                  <div className='text-xl font-bold text-main'>
                    {totalAmount} Rs
                  </div>
                </div>
              </>
            )}
          </div>
          {event?.entry === 'FREE' && (
            <ReCAPTCHA
              className='flex justify-center mb-4'
              sitekey={process.env.REACT_APP_RECAPTCHA_API_SITE_KEY}
              onChange={(v) => setCaptcha(v)}
            />
          )}
          <div className='flex'>
            <IconButton
              circle
              size='lg'
              className='!bg-logo-1 md:hidden !text-white drop-shadow-xl mx-1'
              icon={<AiOutlineShoppingCart />}
              onClick={() => {
                ticketsRef?.current?.scrollIntoView?.({ behavior: 'smooth' });
              }}
            />
            <Button
              appearance='primary'
              loading={freeTicketLoading}
              disabled={
                !(
                  (cart.length && event?.entry === 'PAID') ||
                  (cart.length && captcha)
                )
              }
              onClick={event?.entry === 'PAID' ? onProceed : getFreeTickets}
              className='!bg-main w-full items-center rounded-lg flex justify-center gap-4 p-2 text-white/90'
            >
              {event?.entry === 'PAID' ? 'Proceed' : 'Get Tickets'}
              <HiArrowNarrowRight className='text-lg text-white' />
            </Button>
          </div>
        </div>
      </div>

      {/* Buy Ticket Modal */}
      <Modal
        open={ticketModal}
        onClose={() => {
          setTicketModal(false);
          setValidationErrors({});
        }}
      >
        <Modal.Header className='font-semibold text-center'>
          <div className='text-xl'>
            <span className='font-medium'>Enter Information for Pass: </span>
            {selectedTicket?.title}
          </div>
          <div className='text-lg'>Price: {selectedTicket?.price} Rs</div>
        </Modal.Header>
        <Form fluid onSubmit={addTicketToCart}>
          <Modal.Body className='mx-2 mb-4'>
            {selectedTicket &&
              selectedTicket.options?.map((field) => (
                <Form.Group key={field.name}>
                  <Form.ControlLabel>{field.name}</Form.ControlLabel>
                  <Form.Control
                    name={field.name}
                    pattern={field.pattern}
                    required={field.isRequired}
                    errorMessage={
                      validationErrors[field.name] ? field.errorMessage : null
                    }
                    onChange={(val: string) =>
                      setValidationErrors((old) => ({
                        ...old,
                        [field.name]: !new RegExp(field.pattern).test(val),
                      }))
                    }
                  />
                  {field.isRequired && <Form.HelpText>Required</Form.HelpText>}
                </Form.Group>
              ))}
          </Modal.Body>
          <Modal.Footer className='flex gap-4'>
            {event?.entry === 'PAID' && (
              <InputGroup inside>
                <InputGroup.Addon>
                  <RiCoupon2Line />
                </InputGroup.Addon>
                <Form.Control name='coupon' placeholder='Got A Coupon?' />
              </InputGroup>
            )}
            <Button
              type='submit'
              loading={verifyingCoupon}
              className='!bg-main flex !text-white'
              startIcon={<AiOutlineShoppingCart size={20} />}
            >
              Add
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Edit Ticket Modal */}
      <Modal
        open={!!editedTicket}
        onClose={() => {
          setEditedTicket(null);
          setValidationErrors({});
        }}
      >
        <Modal.Header className='font-semibold text-center'>
          <div className='text-xl'>
            <span className='font-medium'>Edit your Ticket Details</span>
          </div>
          {editedTicket && <CustomerTicketPreview {...editedTicket} />}
        </Modal.Header>
        <Form fluid onSubmit={saveEditedTicket}>
          <Modal.Body className='mx-2 mb-4'>
            {editedTicket &&
              editedTicket.template.options?.map((field) => (
                <Form.Group key={field.name}>
                  <Form.ControlLabel>{field.name}</Form.ControlLabel>
                  <Form.Control
                    name={field.name}
                    pattern={field.pattern}
                    required={field.isRequired}
                    defaultValue={
                      editedTicket.ticket.customerTicketDetails.find(
                        (detail) => detail.name === field.name
                      ).value
                    }
                    errorMessage={
                      validationErrors[field.name] ? field.errorMessage : null
                    }
                    onChange={(val: string) =>
                      setValidationErrors((old) => ({
                        ...old,
                        [field.name]: !new RegExp(field.pattern).test(val),
                      }))
                    }
                  />
                  {field.isRequired && <Form.HelpText>Required</Form.HelpText>}
                </Form.Group>
              ))}
          </Modal.Body>
          <Modal.Footer className='flex gap-4'>
            <InputGroup inside>
              <InputGroup.Addon>
                <RiCoupon2Line />
              </InputGroup.Addon>
              <Form.Control name='coupon' placeholder='Got A Coupon?' />
            </InputGroup>
            <button
              type='submit'
              className='flex items-center p-2 text-white transition-all rounded-lg bg-main/90 group hover:bg-main disabled:bg-main/50'
            >
              <AiOutlineSave size={20} className='mr-2' />
              <span className='whitespace-nowrap'>Save</span>
            </button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Delete Ticket Modal */}
      <Modal open={!!deleteTicket} onClose={() => setDeleteTicket(null)}>
        <Modal.Header>
          <span className='text-xl'>Remove Ticket</span>
        </Modal.Header>
        <Modal.Body className='mx-2'>
          <p>
            Are you sure you want to remove the following ticket from your cart?
          </p>
          <div className='flex flex-col gap-4 p-4 mt-4 overflow-y-auto rounded-lg bg-neutral-100'>
            {deleteTicket && <CustomerTicketPreview {...deleteTicket} />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setDeleteTicket(null)}>Cancel</Button>
          <Button
            color='red'
            appearance='subtle'
            onClick={() => {
              setCart(cart.filter((ticket) => ticket !== deleteTicket));
              setDeleteTicket(null);
            }}
          >
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BuyEventPass;
