import { useContext, createContext, useState } from 'react';
import axios, { AxiosInstance } from 'axios';
import { CookieHelper, deleteCookie, setCookie, setRefreshTokenCookie } from 'helper/cookies';
import { Toast } from 'helper/toast';
import { useLocalStorage } from 'hooks/useStorage';
import { removeAuthToken, setAuthToken } from 'helper/axios';
import { UserContext } from 'types/useUser';
// import { toast } from "react-toastify";

export const _UserContext = createContext(null);
const {
  REACT_APP_BACKEND_PROD_URI,
  REACT_APP_BACKEND_DEV_URI,
  REACT_APP_IS_PROD,
} = process.env;

export const UserProvider: React.FC = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const uri: string =
    REACT_APP_IS_PROD === 'true'
      ? REACT_APP_BACKEND_PROD_URI
      : REACT_APP_BACKEND_DEV_URI;

  const [user, setUser] = useState<{
    name: string;
    email: string;
    password: string;
  }>({
    name: '',
    email: '',
    password: '',
  });

  const [, setIsFirstTime, removeFirstTime] = useLocalStorage(
    'isFirstTime',
    false
  );

  const [useCallback, setUseCallback] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState<number>(0);

  function findIDToken() {
    const cookies = document.cookie.split(';');
    const idToken = cookies.find((e) => e.includes('id_token'));
    if (idToken) {
      setAuthToken(idToken.split('=')[1]);
    }
    return idToken;
  }

  const [hasLogout, setHasLogout] = useState<boolean>(
    findIDToken() ? false : true
  );

  const login = async (
    email: string,
    password: string,
    recaptchaValue: string
  ) => {
    // const res = await axios(options);
    const res = await axios.post(
      uri + 'auth/login',
      {
        email,
        password,
        recaptchaValue,
      },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    // Add cookie with 1 hour expired time
    CookieHelper.setCookie('id_token', res.data.accessToken, 3);
    CookieHelper.setCookie('jwt', res.data.refreshToken, 7);
    setUser({ email, password, name: '' });
    setHasLogout(false);
    setIsFirstTime(false);
    setAuthToken(res.data.accessToken);
    return res;
  };

  const register = async (
    name: string,
    email: string,
    password: string,
    contactNo: string,
    recaptchaValue: string
  ) => {
    try {
      const res = await axios.post(uri + 'auth/signup', {
        name,
        email,
        password,
        contactNo,
        recaptchaValue,
      });
      setIsFirstTime(true);
      return res;
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const logout = () => {
    const token = findIDToken();
    if (token) {
      // remove id_token cookie from browser
      CookieHelper.deleteCookie('id_token');
      CookieHelper.deleteCookie('jwt');
      removeFirstTime();
    }
    setUser({ name: '', email: '', password: '' });
    setHasLogout(true);
    removeAuthToken();
  };

  const isVerified = (): boolean => {
    return findIDToken() ? true : false;
  };

  const getToken = async (): Promise<boolean> => {
    try {
      if (hasLogout) {
        return false;
      }

      const response = await axios.post(
        uri + 'auth/refresh',
        {},
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        CookieHelper.setCookie('id_token', response.data.accessToken, 3);
        return true;
      }
    } catch (error) {
      logout();
      return false;
    }
  };

  const API: AxiosInstance = axios.create({
    baseURL: uri,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });

  API.interceptors.request.use(function (config) {
    config.headers.Authorization =
      'Bearer ' + (findIDToken()?.split('=')[1] ?? '');
    return config;
  });

  API.interceptors.response.use(
    (res) => {
      if (res.data.code >= 400) {
        Toast.error(res.data.message);
        throw new Error(res.data.message);
      } else return res;
    },
    async (error) => {
      const originalRequest = error.config;
      if (
        (error.response.status === 403 || error.response.status === 401) &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        await getToken();
        return await API.request(originalRequest);
      }
      return Promise.reject(error);
    }
  );

  const FormDataAPI: AxiosInstance = axios.create({
    baseURL: uri,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    withCredentials: true,
  });

  FormDataAPI.interceptors.request.use(function (config) {
    config.headers.Authorization =
      'Bearer ' + (findIDToken()?.split('=')[1] ?? '');
    return config;
  });

  FormDataAPI.interceptors.response.use(
    (res) => {
      if (res.data.code >= 400) {
        Toast.error(res.data.message);
        throw new Error(res.data.message);
      } else return res;
    },
    async (error) => {
      console.warn(error);
      const originalRequest = error.config;
      if (
        (error.response.status === 403 || error.response.status === 401) &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        await getToken();
        return await FormDataAPI.request(originalRequest);
      }
      return Promise.reject(error);
    }
  );

  const value = {
    login,
    register,
    logout,
    isVerified,
    getToken,
    API,
    FormDataAPI,
    user,
    uri,
    activeTab,
    useCallback,
    setActiveTab,
    setUseCallback,
  };

  return <_UserContext.Provider value={value}>{children}</_UserContext.Provider>;
};

export const useUser = () => useContext<UserContext>(_UserContext);
