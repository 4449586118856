import { CustomerTicket, TicketTemplate } from 'models/Ticket';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RadioTile, RadioTileGroup } from 'rsuite';
import SecureLS from 'secure-ls';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Coupon,
  PaymentMethod,
  PaymentMethodCodes,
  PaymentMethodIcons,
} from 'models';
import { useUser } from 'context/UserContext';
import { Toast } from 'helper/toast';
import { JazzCashModalFields } from './JazzCashModalFields';

const Checkout = () => {
  const ls = new SecureLS();

  const location = useLocation();
  const eventId = useMemo(() => location.pathname.split('/')[2], [location]);

  const [loadingPaymentMethods, setLoadingPaymentMethods] =
    useState<boolean>(false);
  const [, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodCodes | null>(
    null
  );
  const [charges, setCharges] = useState<{
    serviceCharges: number;
    bankCharges: number;
  }>({ serviceCharges: 0, bankCharges: 0 });

  const [cart] = useState<
    { ticket: CustomerTicket; template: TicketTemplate; coupon?: Coupon }[]
  >(ls.get(`cart_${eventId}`) || []);
  const totalDiscount = useMemo(
    () =>
      cart.reduce((total, ticket) => total + (ticket.coupon?.discount || 0), 0),
    [cart]
  );
  const totalAmount = useMemo(
    () =>
      cart.reduce(
        (total, ticket) =>
          total + ticket.template.price - (ticket.coupon?.discount || 0),
        0
      ),
    [cart, charges]
  );
  const finalAmount = useMemo(
    () =>
      cart.reduce(
        (total, ticket) =>
          total + ticket.template.price - (ticket.coupon?.discount || 0),
        0
      ) +
      +charges.bankCharges +
      +charges.serviceCharges,
    [cart, charges]
  );

  const handlePaymentMethodChange = (value: PaymentMethodCodes) => {
    setPaymentMethod(value);
    // if (value === PaymentMethodCodes.JAZZCASH) setShowJazzCashModal(true);
  };

  const [canRedirect, setCanRedirect] = useState<boolean>(false);
  const [showJazzCashModal, setShowJazzCashModal] = useState<boolean>(false);

  const [captcha, setCaptcha] = useState<string | null>();

  const { API } = useUser();

  useEffect(() => {
    const getPaymentMethods = async () => {
      try {
        setLoadingPaymentMethods(true);
        const res = await API.get('/gateway');
        setPaymentMethods(res.data.dto.gateways);
      } catch {
        Toast.error('Cannot reach payment gateway servers. Try again later.');
        setError('Service Unavailable');
      } finally {
        setLoadingPaymentMethods(false);
      }
    };

    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (!paymentMethod) {
      return setError('Select a Payment Method');
    }
    const getServiceCharges = async () => {
      try {
        setLoading(true);
        const res = await API.post('/ticket/charges', {
          eventId,
          bank: paymentMethod,
          ticketTemplateIds: cart.map((t) => t.template._id),
          totalDiscount: totalDiscount,
        });
        setCharges(res.data.dto);
        setError('');
      } catch {
        Toast.error('Cannot reach payment gateway servers. Try again later.');
        setError('Service Unavailable');
      } finally {
        setLoading(false);
      }
    };

    getServiceCharges();
  }, [paymentMethod]);

  useEffect(() => {
    setCanRedirect(totalAmount !== 0);
  }, [totalAmount, totalDiscount]);

  return (
    <>
      <div className='w-full md:w-[50%] mx-auto rounded-lg bg-white shadow-lg py-8 px-8 md:px-24 md:my-4'>
        <div className='p-4 mx-auto text-center rounded-lg bg-neutral-100 md:w-3/4'>
          <h6 className='mb-2 font-semibold'>Total Amount</h6>
          <h3 className='text-3xl font-bold'>{finalAmount.toFixed(0)} Rs</h3>
        </div>

        <div className='flex flex-col gap-2 p-4 mt-8 border border-gray-300 border-dashed rounded-xl'>
          <div className='flex items-center justify-between'>
            <p className='font-medium'>Tickets:</p>
            <div className='text-lg font-bold'>{cart.length}</div>
          </div>
          <div className='flex items-center justify-between'>
            <p className='font-medium'>Amount:</p>
            <div className='text-lg font-bold'>
              {totalAmount + totalDiscount} Rs
            </div>
          </div>
          <div className='flex items-center justify-between'>
            <p className='font-medium'>Discount:</p>
            <div
              className={`font-bold text-lg ${totalDiscount > 0 ? 'text-green-400' : ''
                }`}
            >
              {totalDiscount} Rs
            </div>
          </div>
          {totalDiscount > 0 && (
            <div className='flex items-center justify-between'>
              <p className='font-medium'>Amount After Discount:</p>
              <div className='text-lg font-bold'>{totalAmount} Rs</div>
            </div>
          )}
          <hr />
          {canRedirect && (
            <div>
              <div className='flex items-center justify-between'>
                <p className='text-xs font-medium'>Service Charges:</p>
                <div className='text-xs font-medium'>
                  {charges.serviceCharges
                    ? `${charges.serviceCharges} Rs`
                    : '---'}
                </div>
              </div>
              <div className='flex items-center justify-between'>
                <p className='text-xs font-medium'>Bank Charges:</p>
                <div className='text-xs font-medium'>
                  {charges.bankCharges ? `${charges.bankCharges} Rs` : '---'}
                </div>
              </div>
            </div>
          )}
          <div
            className={`flex items-center justify-between pt-2 ${canRedirect ? 'border-t-2 border-dashed' : ''
              }`}
          >
            <p className='font-medium'>Total Amount:</p>
            <div className='text-xl font-bold text-main'>
              {canRedirect ? finalAmount.toFixed(0) : totalAmount} Rs
            </div>
          </div>
        </div>
        {canRedirect && (
          <>
            <div className='mt-8 mb-2 font-semibold font-base'>
              Select Payment Method
            </div>
            <RadioTileGroup
              className='flex rounded-lg'
              inline
              name='paymentMethod'
              value={paymentMethod}
              onChange={handlePaymentMethodChange}
            >
              {loadingPaymentMethods && <></>}
              {!loadingPaymentMethods && (
                <>
                  {paymentMethods.map((pm) => (
                    <RadioTile
                      key={pm.code}
                      label={pm.displayName}
                      value={pm.code}
                      disabled={!pm.isActive}
                      className='flex-1 p-4 transition-all'
                      icon={
                        <img
                          className='mr-2'
                          width='60rem'
                          src={PaymentMethodIcons[pm.code]}
                          alt=''
                        />
                      }
                      // onClick={() => pm.code === PaymentMethodCodes.JAZZCASH && setShowJazzCashModal(true)}
                    />
                  ))}
                </>
              )}
            </RadioTileGroup>
          </>
        )}
        <ReCAPTCHA
          className='flex justify-center mt-6'
          sitekey={process.env.REACT_APP_RECAPTCHA_API_SITE_KEY}
          onChange={(v) => setCaptcha(v)}
        />
        <div className='mx-16 mt-4'>
          <Link
            to={`/${canRedirect ? paymentMethod : 'ALFALAH'
              }/checkout?captcha=${captcha}&eventId=${eventId}&canRedirect=${canRedirect}`}
            className={`block w-full px-4 py-2 text-center text-white transition-all rounded-lg hover:no-underline hover:text-white ${canRedirect
              ? !!error || !captcha
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-main'
              : 'bg-main'
              }`}
          >
            {canRedirect ? error || 'Checkout' : 'Get Ticket'}
          </Link>
          <div className='mt-2 text-xs text-center text-neutral-600'>
            On clicking &apos;{canRedirect ? 'Checkout' : 'Get Ticket'}&apos; you
            will be redirected to the{' '}
            {canRedirect
              ? 'selected payment method to complete your transaction.'
              : 'home page'}
          </div>
        </div>
      </div>
      <JazzCashModalFields open={showJazzCashModal} eventId={eventId} onClose={() => setShowJazzCashModal(false)} />
    </>
  );
};

export default Checkout;
