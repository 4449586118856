import axios from 'axios';
import { CookieHelper, getCookie, setCookie } from './cookies';

const { REACT_APP_BACKEND_PROD_URI, REACT_APP_BACKEND_DEV_URI, REACT_APP_IS_PROD } = process.env;
const url = REACT_APP_IS_PROD === 'true' ? REACT_APP_BACKEND_PROD_URI : REACT_APP_BACKEND_DEV_URI;

export interface IResponse<T> {
  dto: T;
  message: string;
  code: number;
}

export const API = axios.create({
  baseURL: url,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export const FormDataAPI = axios.create({
  baseURL: url,
  timeout: 30000,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  withCredentials: true,
});

export const setAuthToken = (token: string) => {
  API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  FormDataAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const removeAuthToken = () => {
  if (API.defaults.headers.common['Authorization']) {
    delete API.defaults.headers.common['Authorization'];
  }
  if (FormDataAPI.defaults.headers.common['Authorization']) {
    delete FormDataAPI.defaults.headers.common['Authorization'];
  }
};

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.error(error);
    const originalRequest = error.config;
    if ([401, 403].includes(error.response.status) && !originalRequest._retry) {
      removeAuthToken();
      originalRequest._retry = true;
      await getAuthToken();
      
      const token = CookieHelper.getCookie('id_token');
      if (!token) return Promise.reject(error);
      setAuthToken(token);
      
      return API.request(originalRequest);
    }
    return Promise.reject(error);
  },
);

FormDataAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.error(error);
    const originalRequest = error.config;
    if ([401, 403].includes(error.response.status) && !originalRequest._retry) {
      removeAuthToken();
      originalRequest._retry = true;
      await getAuthToken();

      const token = CookieHelper.getCookie('id_token');
      if (!token) return Promise.reject(error);
      setAuthToken(token);
      
      return API.request(originalRequest);
    }
    return Promise.reject(error);
  },
);


async function getAuthToken() {
  try {
    const response = await axios.post(
      url + 'auth/refresh',
      {},
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      CookieHelper.setCookie('id_token', response.data.accessToken, 3);
      return true;
    }
  } catch (error) {
    return false;
  }
}