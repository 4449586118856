import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import RoutesPath from '../../../routes/types';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { FaSearch, FaList, FaTicketAlt, FaClipboard } from 'react-icons/fa';
import { BsFillCalendarFill } from 'react-icons/bs';
import Popup from '../../../components/Popup/Popup';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useUser } from '../../../context/UserContext';
import { toast } from 'react-toastify';
import { getDayAndMonthAndYear } from 'helper/date';
import { Toast } from 'helper/toast';
import { StatusBgColor } from 'constants/EventsData';

const Events = () => {
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState('list');
  const [data, setData] = useState([]);

  const [isPopupOpen, setIsPopupOpen] = useState(data.map(() => false));
  const [isViewModePopupOpen, setIsViewModePopupOpen] = useState(false);

  // const [progress, setProgress] = useState(0);
  const [searchText, setSearchText] = useState('');
  
  const { API } = useUser();

  const searchEvents = () => {
    if (searchText === '') return data;
    return data.filter((event) => {
      return event.name.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        let response = await API.get('/organizer');
        console.log(response);
        setData(
          response.data.dto?.map((event) => ({
            ...event,
            totalTickets: 100,
            soldTickets: 50,
            gross: 10000,
          }))
        );
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      } finally {
        setSearchText('');
      }
    };

    fetchEvents();
  }, [API, navigate]);

  useEffect(() => {
    console.log(searchText);
  }, [searchText]);

  const popupRef = useRef();
  useOutsideClick(popupRef, () => setIsPopupOpen(isPopupOpen.map(() => false)));

  // const handlePopup = (index) => {
  //   setIsPopupOpen(prev => {
  //     const newState = prev.map(() => false);
  //     newState[index] = true;
  //     return newState;
  //   });
  // };

  const handleNavigate = (id) => {
    navigate(`${RoutesPath.organizeEvent}${RoutesPath.manageEvent}/${id}`, {
      state: data.find((event) => event._id === id),
    });
  };

  const renderEventContent = (eventInfo) => {
    console.log(eventInfo.event.extendedProps);
    const { ...event } = eventInfo.event.extendedProps;
    return (
      <div
        onClick={() => handleNavigate(event._id)}
        className='flex flex-col w-48 gap-2 p-2 bg-blue-100 rounded cursor-pointer'
      >
        <p className='font-bold'>{event.name}</p>
        <p className='overflow-hidden text-sm text-gray-500 truncate'>
          {event.description}
        </p>
      </div>
    );
  };

  const replaceSpaceWithHtml = (text) => {
    return text.replace(/ /g, '%20');
  };

  return (
    <div className='w-full'>
      <div className='flex flex-col gap-4 mb-10'>
        <div className='flex flex-col-reverse items-center justify-between w-full sm:flex sm:flex-row gap-y-2'>
          <div className='flex items-center w-full gap-2'>
            <>
              {viewMode === 'list' && (
                <div className='flex items-center px-2 py-3 space-x-2 bg-gray-100 border rounded focus:outline-none focus:border-2 focus:border-main'>
                  <FaSearch size={20} color='#4A6CD1' />
                  <input
                    id='search'
                    name='search'
                    type='text'
                    placeholder='Search'
                    value={searchText}
                    autoComplete='off'
                    className='w-[calc(80vw-100px)] mr-10 text-gray-700 bg-transparent sm:w-52 focus:outline-none'
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              )}
              <Popup
                isVisible={isViewModePopupOpen}
                setVisibility={setIsViewModePopupOpen}
                title={viewMode.charAt(0).toUpperCase() + viewMode.slice(1)}
                className='!w-fit'
              >
                <div className='absolute left-0 z-50 flex flex-col w-48 mt-2 bg-black rounded-lg drop-shadow-2xl'>
                  <div
                    onClick={() => {
                      setViewMode('list');
                      setIsViewModePopupOpen(false);
                    }}
                    className={`flex gap-4 ${
                      viewMode === 'list'
                        ? 'bg-main text-white'
                        : 'bg-gray-200 text-gray-500'
                    } p-2 cursor-pointer px-6 items-center rounded-t-md`}
                  >
                    <FaList size={16} />
                    <p className='font-bold'>List</p>
                  </div>
                  <div
                    onClick={() => {
                      setViewMode('calendar');
                      setIsViewModePopupOpen(false);
                    }}
                    className={`flex gap-4 ${
                      viewMode === 'calendar'
                        ? 'bg-main text-white'
                        : 'bg-gray-200 text-gray-500'
                    } p-2 cursor-pointer px-6 items-center rounded-b-md`}
                  >
                    <BsFillCalendarFill size={16} />
                    <p className='font-bold'>Calendar</p>
                  </div>
                </div>
              </Popup>
              <div className='flex items-center gap-2'>
                {/* <div className="relative inline-block text-left"> */}
                {/* <Popup
                      title={filter}
                      isVisible={isFilterPopupOpen}
                      setVisibility={setIsFilterPopupOpen}
                    >
                      <div className="absolute left-0 z-10 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg w-60 ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {FiltersData.map((item, index) => (
                          <div
                            key={index}
                            className={`py-1 hover:bg-main hover:text-white text-gray-700 
                              ${index === 0 ? "hover:rounded-t-md" : ""} 
                              ${index === FiltersData.length - 1
                                ? "hover:rounded-b-md"
                                : ""
                              }`}
                          >
                            <Link
                              href="#"
                              onClick={() => {
                                setIsFilterPopupOpen(false);
                                setFilter(item.title);
                              }}
                              className="block px-4 py-2 text-sm hover:text-white hover:no-underline"
                            >
                              {item.title}
                            </Link>
                          </div>
                        ))}
                      </div>
                    </Popup> */}
                {/* </div> */}
              </div>
            </>
          </div>
          <div className='flex flex-row-reverse items-center w-full gap-2'>
            <button
              className='flex items-center w-full gap-2 p-2 px-6 bg-white border-2 rounded sm:w-fit border-main text-main'
              onClick={() =>
                navigate(RoutesPath.organizeEvent + RoutesPath.createEvent)
              }
            >
              <FaTicketAlt size={18} className='hidden sm:block' />
              <p className='w-full text-lg font-semibold align-middle'>
                Create Event
              </p>
            </button>
          </div>
        </div>
        <div className='max-h-[24rem] h-[24rem] overflow-auto'>
          {viewMode === 'list' ? (
            <table className='table-auto'>
              <thead>
                <tr className='p-4 bg-gray-300'>
                  <th className='p-1 text-sm font-bold text-center md:p-4 rounded-tl-md md:w-1/4 md:text-lg'>
                    Title
                  </th>
                  <th className='hidden p-1 text-sm font-bold text-center md:p-4 md:table-cell md:w-1/4 md:text-lg'>
                    Location
                  </th>
                  <th className='md:p-4 p-1 text-sm font-bold md:w-1/4 text-center md:text-lg min-w-[7rem]'>
                    Date-Time
                  </th>
                  <th className='md:p-4 p-1 text-sm font-bold md:w-1/4 text-center md:text-lg min-w-[5rem]'>
                    Event URL
                  </th>
                  <th className='p-1 text-sm font-bold text-center md:p-4 rounded-tr-md md:w-1/4 md:text-lg'>
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {searchEvents(searchText).map((event) => (
                  <tr
                    key={event._id}
                    className='bg-gray-100 justify-evenly hover:bg-blue-200 hover:border-l-2 hover:border-main'
                  >
                    <td
                      className='p-4 cursor-pointer'
                      onClick={() => handleNavigate(event._id)}
                    >
                      <div className='flex flex-col items-center gap-4 overflow-hidden md:flex-row'>
                        <img
                          src={event.picture}
                          className='block object-contain w-8 h-8 rounded md:w-12 md:h-12'
                          alt={event.name}
                        />
                        <p className='font-bold md:text-xl text-sm md:max-w-[12rem] max-w-[7rem] md:min-w-[10rem] text-ellipsis overflow-hidden'>
                          {event.name}
                        </p>
                      </div>
                    </td>
                    <td
                      onClick={() => handleNavigate(event._id)}
                      className='hidden gap-4 text-center sm:table-cell'
                    >
                      <p className='mx-auto text-center text-gray-500'>
                        {event?.site?.location + '-' + event?.site?.address}
                      </p>
                    </td>
                    <td
                      onClick={() => handleNavigate(event._id)}
                      className='gap-4 text-xs text-center sm:text-base'
                    >
                      <p className='text-gray-500'>
                        {getDayAndMonthAndYear(event.from)} -{' '}
                        {getDayAndMonthAndYear(event.to)}
                      </p>
                    </td>
                    <td
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${window.location.host}/events/${
                            event._id
                          }?${replaceSpaceWithHtml(event.name)}`
                        );
                        Toast.success('Copied to clipboard', {
                          autoClose: 1000,
                        });
                      }}
                    >
                      <div className='flex flex-wrap justify-center gap-2 my-auto overflow-hidden align-middle h-fit'>
                        <FaClipboard
                          className='my-auto sm:text-2xl '
                          size={20}
                        />
                        <span className='hidden sm:flex text-xs max-w-[10rem] min-w-[10rem] text-ellipsis overflow-hidden hover:underline hover:text-main cursor-default'>
                          {`${window.location.host}/events/${
                            event._id
                          }?${replaceSpaceWithHtml(event.name)}`}
                        </span>
                      </div>
                    </td>
                    <td
                      onClick={() => handleNavigate(event._id)}
                      className='p-2'
                    >
                      <div
                        className={`p-1  text-[0.5rem] sm:text-base sm:p-2 ${
                          StatusBgColor[event.eventStatus]
                        } rounded-lg text-center text-white font-thin sm:font-semibold`}
                      >
                        {event.eventStatus}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <FullCalendar
              plugins={[dayGridPlugin]}
              events={searchEvents(searchText).map((event) => {
                return {
                  ...event,
                  title: event.name,
                  date: event.from,
                };
              })}
              initialView='dayGridMonth'
              eventContent={renderEventContent}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Events;
