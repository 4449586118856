import OrganizeEventLayout from 'layouts/OrganizeEventLayout';
import { Competitions } from 'pages/OrganizeCompetitions';
import Events from 'pages/OrganizeEvents/Events';
import { Trips } from 'pages/OrganizeTrips';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Nav } from 'rsuite';

export const Activities: React.FC = () => {
  const lists = ['Events', 'Competitions', 'Trips'];
  const [activityValue, setActivityValue] = useSearchParams({ tab: lists[0] });

  return (
    <OrganizeEventLayout>
      <div className='flex flex-col gap-4 px-4 py-4'>
        <span className='text-4xl font-bold'>Activities</span>
        <Nav
          activeKey={activityValue.get('tab')}
          onSelect={(value) => setActivityValue({ tab: value })}
          appearance='subtle'
          justified
          className='text-center rounded'
        >
          {lists.map((list) => (
            <Nav.Item key={list} eventKey={list} className='text-xl'>
              {list}
            </Nav.Item>
          ))}
        </Nav>

        {activityValue.get('tab') === 'Events' && <Events />}
        {activityValue.get('tab') === 'Competitions' && <Competitions />}
        {activityValue.get('tab') === 'Trips' && <Trips />}
      </div>
    </OrganizeEventLayout>
  );
};
