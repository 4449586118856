import { images } from 'assets';
import { Coupon } from 'models';
import { CustomerTicket, TicketTemplate } from 'models/Ticket';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { IconButton } from 'rsuite';

interface CustomerTicketPreviewProps {
  ticket: CustomerTicket;
  template: TicketTemplate;
  coupon?: Coupon;
  selectable?: boolean;
  selected?: boolean;
  editable?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
}

const CustomerTicketPreview: React.FC<CustomerTicketPreviewProps> = ({
  ticket,
  template,
  coupon,
  selectable,
  selected,
  editable,
  onClick,
  onDelete,
  onEdit,
}) => {
  return (
    <div
      className={`rounded-lg flex gap-6 ${selected ? 'bg-logo-2' : 'bg-white'} py-4 drop-shadow-md transition-all ${selectable ? 'cursor-pointer hover:scale-105' : ''}`}
      onClick={() => onClick?.()}
    >
      <div className="bg-neutral-100 absolute top-[calc(50%-0.75rem)] h-6 w-3 rounded-e-2xl"></div>
      <div className="flex flex-[3.5] items-center ml-4 border-r-2 border-dashed">
        <img className="w-16 h-16" src={images.svg.FestiveQR} alt="" />
        <div className="flex flex-col self-center flex-1 gap-1 px-2">
          {[...Array(4)].map((_e, i) => <div className={`${selected ? 'bg-white' : 'bg-logo-2'} rounded-lg h-2`} key={i}></div>)}
        </div>
      </div>
      <div className="flex-[6] mr-2 mt-2">
        <h4 className={`font-semibold text-lg ${selected ? 'text-white' : ''}`}>
          {ticket.customerTicketDetails[0].value}
        </h4>
        <h5 className={`text-xs font-medium truncate md:text-base ${selected ? 'text-white' : 'text-logo-2'} max-w-[6.25rem] md:max-w-[15rem]`}>
          {template.title}
        </h5>
      </div>
      <div className="bg-neutral-100 absolute right-0 top-[calc(50%-0.75rem)] h-6 w-3 rounded-s-2xl"></div>
      {coupon && (
        <div className="absolute right-0 md:right-2 bottom-3 md:bottom-6 bg-logo-1 text-white border border-black rounded-lg px-2 -rotate-[35deg] text-[0.6rem] font-medium">
          -{coupon?.discount} Rs
        </div>
      )}
      {editable && (
        <div className="absolute top-0 right-0 ">
          <IconButton
            circle
            icon={<AiFillEdit />}
            onClick={() => onEdit?.()}
            className="!text-green-500 !text-base"
          />
          <IconButton
            circle
            icon={<AiFillDelete />}
            onClick={() => onDelete?.()}
            className="!text-red-500 !text-base"
          />
        </div>
      )}
    </div>
  );
};

export default CustomerTicketPreview;
