import { BsEmojiSunglasses, BsInstagram, BsLinkedin } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import RoutesPath from '../../routes/types';
import { images } from '../../assets';

const Footer = () => {
  return (
    <div className='bg-[#18181A] px-10'>
      <div className='py-8'>
        <div className='grid w-full grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3'>
          <div className='flex flex-col justify-between gap-6'>
            <p className='text-2xl font-bold text-white'>Festivity</p>
            <div className='flex justify-start text-4xl text-yellow-500'>
              <BsEmojiSunglasses
                style={{ animationFillMode: 'forwards' }}
                className='hover:animate-wiggle'
              />
            </div>
            <div className='flex space-x-6 text-2xl text-main'>
              {/* <BsFacebook /> */}
              {/* <BsGithub /> */}
              {/* <BsTwitter /> */}
              <a
                href='https://www.instagram.com/nobeltenlife/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <BsInstagram />
              </a>
              <a
                href='https://www.linkedin.com/company/nobelten'
                target='_blank'
                rel='noopener noreferrer'
              >
                <BsLinkedin />
              </a>
            </div>
          </div>
          <div className='flex justify-between w-full text-white'>
            <div className='flex flex-col space-y-4 text-white'>
              <h5 className='font-bold'>ABOUT US</h5>
              <div className='grid w-full grid-cols-1 gap-4 md:grid-cols-2'>
                <Link className='text-sm' to={RoutesPath.contact}>
                  Contact
                </Link>
                <Link className='text-sm' to={RoutesPath.termsAndConditions}>
                  Terms and Conditions
                </Link>
                <Link className='text-sm' to={RoutesPath.privacyPolicy}>
                  Privacy Policy
                </Link>
                <Link className='text-sm' to={RoutesPath.refundPolicy}>
                  Refund/Exchange Policy
                </Link>
                <Link className='text-sm' to={RoutesPath.faqs}>
                  FAQs
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col justify-between text-white">
            <div className="space-y-4 text-white">
              <h5 className="font-bold">COMPANY</h5>
              <p className="text-sm">About us</p>
              <p className="text-sm">Our Values</p>
              <p className="text-sm">Affiliate Program</p>
              <p className="text-sm">Service partners</p>
              <p className="text-sm">Careers</p>
              <p className="text-sm">Our Products</p>
            </div>
          </div>
          <div className="flex flex-col justify-between text-white">
            <div className="space-y-4">
              <h5 className="font-bold">PLAN EVENTS</h5>
              <p className="text-sm">Sell Ticket</p>
              <p className="text-sm">Online RSVP</p>
              <p className="text-sm">Online Events</p>
            </div>
            <div className="flex items-center mt-4">
              <input
                type="text"
                placeholder="Subscribe"
                className="p-2 text-black rounded"
              />
              <button className="relative z-10 px-3 py-2 text-white border-2 rounded bg-main active:bg-blue-500 -left-2 border-main">
                <BsArrowRight size={25} />
              </button>
            </div>
          </div> */}
        </div>
      </div>
      <hr />
      <div className='flex items-center justify-center gap-6 py-4'>
        <div className='flex flex-col items-center justify-center text-white md:flex-row'>
          <p className='m-0 md:mr-2'>Copyright © 2023</p>{' '}
          <a
            href='https://www.nobel10.com'
            target='_blank'
            rel='noopener noreferrer'
            className='m-0'
          >
            Nobel10 Technologies
          </a>
        </div>
        <div className='flex flex-col items-center justify-center text-white md:flex-row md:gap-2'>
          <p className='m-0 md:mr-2'>Powered by</p>{' '}
          <a
            href='https://www.jazzcash.com.pk'
            target='_blank'
            rel='noopener noreferrer'
            className='m-0'
          >
            <img
              src={images.logos.jazzcash}
              alt='JazzCash'
              className='object-contain w-12 h-12'
            />
          </a>
          <a
            href='https://www.bankalfalah.com/'
            target='_blank'
            rel='noopener noreferrer'
            className='m-0'
          >
            <img
                src={images.logos.alfalah}
                alt='Alfalah Bank'
                className='object-contain w-12 h-12'
              />
            </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
