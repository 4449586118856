export function setCookie(
  cookieName: string,
  cookieValue: string,
  expiryHour: number
) {
  const date = new Date();
  date.setTime(date.getTime() + expiryHour * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';path=/';
}

export function setRefreshTokenCookie(
  cookieName: string,
  cookieValue: string,
  expiryDays: number
) {
  const date = new Date();
  date.setTime(date.getTime() + expiryDays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie =
    cookieName +
    '=' +
    cookieValue +
    ';' +
    'domain=' +
    process.env.REACT_APP_DOMAIN_URL +
    ';' +
    expires +
    ';path=/;SameSite=None;Secure';
}

export function getCookie(cookieName: string) {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return false;
}

export function checkCookie(cookieName: string) {
  const cookie = CookieHelper.getCookie(cookieName);
  return cookie !== null && cookie !== undefined;
}

export function deleteCookie(cookieName: string) {
  document.cookie =
    cookieName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
}


export class CookieHelper {	
	private static domain = `domain=.${process.env.REACT_APP_DOMAIN_URL}`;
	private static path = 'path=/';

	static getCookie(name: string) {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop()?.split(';').shift();
		return null;
	}

	static setCookie(name: string, value: string, days: number) {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		const cookie = `${name}=${value}`;
		const expires = `expires=${date.toUTCString()}`;

		const host = location.hostname;
		const parts = host.split('.');

		// Running on localhost
		if (host === 'localhost') {
			document.cookie = `${cookie}; ${expires}; ${this.path};`;
			return;
		}
		
		if (parts.length > 2) parts.shift();

		const domain = parts.join('.');
		this.domain = `domain=.${domain}`;
		
		document.cookie = `${cookie}; ${expires}; ${this.path}; ${this.domain}`;

		// check if cookie was successfuly set to the given domain
		// (otherwise it was a Top-Level Domain)
		if (this.getCookie(name) === null || this.getCookie(name) === undefined || this.getCookie(name) !== value) {
			this.domain = `domain=.${host}`;
			document.cookie = `${cookie}; ${expires}; ${this.path}; ${this.domain}`;
		}
	}

	static deleteCookie(name: string) {
		this.setCookie(name, '', -1);
	}
}