import { Loader } from 'rsuite';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  text?: string;
  loading?: boolean;
  Icon?: React.ReactNode;
  type: 'button' | 'submit' | 'reset';
  variant: 'primary' | 'secondary' | 'danger' | 'success' | 'warning' | 'info' | 'transparent';
}
const Button: React.FC<ButtonProps> = ({
  text,
  loading,
  Icon,
  variant = 'primary',
  ...props
}) => {
  const loaderClass = () => {
    let _class = '';

    if (loading) {
      _class = 'mr-2';
    } else {
      _class = 'hidden';
    }

    return _class;
  };

  const colors = {
    primary: 'bg-main hover:bg-blue-600 active:bg-blue-500 focus:ring-blue-500 focus:ring-2 focus:ring-offset-2',
    secondary: 'bg-gray-400 hover:bg-gray-500 active:bg-gray-400 focus:ring-gray-500 focus:ring-2 focus:ring-offset-2',
    danger: 'bg-red-500 hover:bg-red-600 active:bg-red-700 focus:ring-red-500 focus:ring-2 focus:ring-offset-2',
    success: 'bg-green-500 hover:bg-green-600 active:bg-green-700 focus:ring-green-500 focus:ring-2 focus:ring-offset-2',
    warning: 'bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 focus:ring-yellow-500 focus:ring-2 focus:ring-offset-2',
    info: 'bg-blue-500 hover:bg-blue-600 active:bg-blue-700 focus:ring-blue-500 focus:ring-2 focus:ring-offset-2',
    transparent: 'bg-transparent hover:bg-transparent active:bg-transparent',
  };

  return (
    <button
      type={props.type ?? 'button'}
      onClick={props.onClick}
      disabled={loading ? true : false}
      className={`flex items-center justify-center text-white px-10 rounded-lg py-1.5 font-semibold transition-all duration-300 ${colors[variant]} ${props.className}`}
    >
      <Loader className={loaderClass()} />
      {Icon && Icon}
      {text ? text : props.children}
    </button>
  );
};

export default Button;
