import CryptoJS from 'crypto-js';
import { IJazzCashMobileOrderRequest, IJazzCashMobileOrderResponse, JazzCashOrderRequest } from 'models';
import { Toast } from 'helper/toast';
import { API } from 'helper/axios';

const isProd = process.env.REACT_APP_IS_PROD !== 'false'; 
const SALT = isProd ? process.env.REACT_APP_INTEGRITY_SALT : process.env.REACT_APP_SANDBOX_INTEGRITY_SALT;
const MERCHANT_ID = isProd ? process.env.REACT_APP_JAZZCASH_MERCHANT_ID : process.env.REACT_APP_SANDBOX_MERCHANT_ID;
const MERCHANT_PASSWORD = isProd ? process.env.REACT_APP_JAZZCASH_MERCHANT_PASSWORD : process.env.REACT_APP_SANDBOX_MERCHANT_PASSWORD;
const POST_URL = isProd ? process.env.REACT_APP_JAZZCASH_PRODUCTION_API : process.env.REACT_APP_JAZZCASH_SANDBOX_API;
// const POST_MOBILE_URL = isProd ? process.env.REACT_APP_JAZZCASH_PRODUCTION_MOBILE_API : process.env.REACT_APP_JAZZCASH_SANDBOX_MOBILE_API;
const generateJazzCashClientRequest = (serverRequest: JazzCashOrderRequest) => {

    const clientRequest = {
        ...serverRequest,
        pp_MerchantID: MERCHANT_ID,
        pp_Password: MERCHANT_PASSWORD,
    };

    const sortedList = new Map();
    Object.keys(clientRequest).sort().forEach(key => {
        if (key !== 'pp_SecureHash')
            sortedList.set(key, clientRequest[key]);
        }
    );

    let finalString = SALT + '&';
    sortedList.forEach(value => {
        if (value) {
            finalString = finalString.concat(value, '&');
        }
    });
    finalString = finalString.slice(0, finalString.length - 1);
    const hmac256 = CryptoJS.HmacSHA256(finalString, SALT).toString(CryptoJS.enc.Hex);

    clientRequest.pp_SecureHash = hmac256;
    return clientRequest;
};

const handleJazzCashRequest = (request: { canRedirect: boolean; paymentGatewayRequest: JazzCashOrderRequest }): boolean => {
    const { canRedirect, paymentGatewayRequest: serverRequest } = request;

    if (!canRedirect) {
        //free pass
        Toast.success('Tickets bought successfully');
        return true;
    }

    const clientRequest = generateJazzCashClientRequest(serverRequest);
    console.log(clientRequest, serverRequest);
    console.log(clientRequest.pp_SecureHash, serverRequest.pp_SecureHash);

    if (clientRequest.pp_SecureHash === serverRequest.pp_SecureHash) {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = POST_URL;

        for (const key in clientRequest) {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = key;
          input.value = clientRequest[key];
          form.appendChild(input);
        }

        document.body.appendChild(form);
        form.submit();
        Toast.success('Redirecting to JazzCash payments. Please wait...', {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
        });
        return true;
    } else {
        Toast.error('Invalid Hash. Please try again.');
    }
    return false;
};

async function initiateMobileWalletRequest(customerId: string, email: string, billRef: string, txnTimeStamp: string, expiryTimeStamp: string, amount: string, phoneNo: string, cnic: string): Promise<IJazzCashMobileOrderResponse> {

    try {

        const gatewayRequest: IJazzCashMobileOrderRequest = {
            pp_Language: 'EN',
            pp_MerchantID: MERCHANT_ID,
            pp_SubMerchantID: '',
            pp_Password: MERCHANT_PASSWORD,
            pp_BankID: '',
            pp_ProductID: '',
            pp_TxnRefNo: 'T' + txnTimeStamp,
            pp_Amount: amount,
            pp_TxnCurrency: 'PKR',
            pp_TxnDateTime: txnTimeStamp,
            pp_BillReference: 'billRef',
            pp_Description: 'Event Ticketing',
            pp_TxnExpiryDateTime: expiryTimeStamp,
            pp_SecureHash: '',
            ppmpf_1: '1',
            ppmpf_2: billRef,
            ppmpf_3: customerId,
            ppmpf_4: '4',
            ppmpf_5: '5',
            pp_MobileNumber: phoneNo,
            pp_CNIC: cnic,
        };

        const sortedList = new Map<string, string>();

        Object.keys(gatewayRequest).sort().forEach(function (key) {
            if (key !== 'pp_SecureHash')
                sortedList.set(key, gatewayRequest[key as keyof IJazzCashMobileOrderRequest]);
        });

        let finalString = SALT + '&';

        sortedList.forEach((value) => {

            if (value != null && value != '')
                finalString = finalString.concat(value, '&');
        });

        finalString = finalString.slice(0, finalString.length - 1);

        const hmac256 = CryptoJS.HmacSHA256(finalString, SALT).toString(CryptoJS.enc.Hex);

        gatewayRequest.pp_SecureHash = hmac256;

        console.log('Status Request: ', gatewayRequest);

        const { data }  = await API.post('/checkout/jazzcash', {
            gatewayRequest,
            phoneNo: phoneNo,
            cnic: cnic,
        });

        const response = data.dto as IJazzCashMobileOrderResponse;
        console.log('JC Response: ', response);
        return response;
    }
    catch (err) {
        console.log(err);
        throw err;
    }
}

export { handleJazzCashRequest, initiateMobileWalletRequest };
