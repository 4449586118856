import ArticleLayout from './ArticleLayout';
import { MdDashboard, MdEvent, MdOutlineVerified } from 'react-icons/md';
import { FiSettings } from 'react-icons/fi';
import { HiCash } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import RoutesPath from '../routes/types';
import { useUser } from 'context/UserContext';
import React from 'react';

interface OrganizeEventLayoutProps {
  children: React.ReactNode;
  bool?: boolean;
}

const OrganizeEventLayout: React.FC<OrganizeEventLayoutProps> = ({
  children,
  bool,
}) => {
  const tabs = [
    {
      title: 'Dashboard',
      to: RoutesPath.organizeEvent + RoutesPath.dashboard,
      icon: <MdDashboard className='text-sm md:text-3xl' />,
      mobileIcon: <MdDashboard className='text-base md:text-2xl' />,
    },
    {
      title: 'Activities',
      to: RoutesPath.activities,
      icon: <MdEvent className='text-sm md:text-3xl' />,
      mobileIcon: <MdEvent className='text-base md:text-2xl' />,
    },
    // {
    //   title: 'Finance',
    //   to: RoutesPath.organizeEvent + RoutesPath.finance,
    //   icon: <HiCash className='text-sm md:text-3xl' />,
    //   mobileIcon: <HiCash className='text-base md:text-2xl' />,
    // },
    {
      title: 'Validators',
      to: RoutesPath.organizeEvent + RoutesPath.validators,
      icon: <MdOutlineVerified className='text-sm md:text-3xl' />,
      mobileIcon: <MdOutlineVerified className='text-base md:text-2xl' />,
    },
    {
      title: 'Profile',
      to: RoutesPath.organizeEvent + RoutesPath.organizerProfile,
      icon: <FiSettings className='text-sm md:text-3xl' />,
      mobileIcon: <FiSettings className='text-base md:text-2xl' />,
    },
  ];
  const { activeTab, setActiveTab } = useUser();
  return (
    <ArticleLayout footer={bool} animate={false} isOrganizerView={true}>
      <div className={'fixed bottom-0 left-0 w-full z-50'}>
        <div className='flex justify-center w-full gap-5 px-2 py-1 overflow-x-auto text-white border-t-2 bg-main'>
          {tabs.map((tab, index) => (
            <div key={index}>
              <Link
                to={tab.to}
                title={tab.title}
                onClick={() => setActiveTab(index)}
                className='flex items-center'
              >
                <div
                  className={`flex flex-row gap-1 items-center group hover:bg-[#2a5eb8] hover:no-underline hover:text-white transition p-2 rounded-lg ${
                    activeTab === index && 'bg-[#0649bc]'
                  }`}
                >
                  {tab.mobileIcon}
                  <span className='hidden md:block md:text-xs'>
                    {tab.title}
                  </span>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className='w-full min-h-screen'>{children}</div>
    </ArticleLayout>
  );
};

export default OrganizeEventLayout;
