import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Container, Content, Footer, Header } from 'rsuite';
import TopBar from '../components/Header/Header';
import BottomNav from '../components/Footer/Footer';

interface ArticleLayoutProps {
  children: React.ReactNode;
  bool?: boolean;
  bool2?: boolean;
  animate?: boolean;
  footer?: boolean;
  isOrganizerView?: boolean;
  header?: boolean;
}

const ArticleLayout: React.FC<ArticleLayoutProps> = ({
  children,
  footer,
  isOrganizerView,
  header = true,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    (window as any).scrollBehavior = 'auto'; // or "initial"

    // Clean up on unmount
    return () => {
      (window as any).scrollBehavior = 'smooth';
    };
  }, []);

  return (
    <Container className={isOrganizerView ? 'bg-gray-100' : 'bg-white'}>
      {header ? <Header><TopBar /></Header> : null}
      <Content
        className={'max-w-[1550px] mx-auto w-full min-h-[calc(100vh-5rem)]'}
      >
        {children}
      </Content>
      {footer ? (
        <Footer>
          <BottomNav />
        </Footer>
      ) : (
        <div />
      )}
    </Container>
  );
};

export default ArticleLayout;
