import { useUser } from 'context/UserContext';
import { useLocalStorage } from 'hooks/useStorage';
import { Suspense } from 'react';
import { Outlet, useLocation } from 'react-router';
import { Loader } from 'rsuite';
import { UserContext } from 'types/useUser';
// import RoutesPath from './types';

export const PublicRoutes = () => {
  const location = useLocation();
  const { isVerified } = useUser() as UserContext;
  const [isFirstTime] = useLocalStorage('isFirstTime', false);

  return isFirstTime && isVerified() === true ? (
    <Suspense
      fallback={
        <div className='flex items-center justify-center w-screen h-screen'>
          <Loader size='lg' className='mx-auto' />
        </div>
      }
    >
      <Outlet />
    </Suspense>
  ) : (
    <Suspense
      fallback={
        <div className='flex items-center justify-center w-screen h-screen'>
          <Loader size='lg' className='mx-auto' />
        </div>
      }
    >
      <Outlet />
      {/* <Navigate to={RoutesPath.onboarding} state={{ from: location }} replace /> */}
    </Suspense>
  );
};
