import { Suspense } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import RoutesPath from './types';
import { UserContext } from 'types/useUser';
import { Loader } from 'rsuite';

const PrivateRoutes = () => {
  const location = useLocation();
  const { isVerified } = useUser() as UserContext;

  return isVerified() ? (
    <Suspense
      fallback={
        <div className='flex items-center justify-center w-screen h-screen'>
          <Loader size='lg' className='mx-auto' />
        </div>
      }
    >
      <Outlet />
    </Suspense>
  ) : (
    <Navigate to={RoutesPath.auth} state={{ from: location }} replace />
  );
};

export default PrivateRoutes;
