const minChar = 3;
const maxChar = 200;

const isValidEmail = (email) => {
  const regex = /^.+@.+$/;
  return regex.test(email);
};

const isValidCnic = (cnic) => {
  const regex = /^[0-9]{13}$/;

  return regex.test(cnic);
};

const isValidName = (name) => {
  const regex = /^[a-zA-Z ]{3,50}$/;
  return regex.test(name);
};

const isValidAddress = (address) => {
  const regex = /^[a-zA-Z0-9 ]{3,300}$/;
  return regex.test(address);
};

const isValidAge = (age) => {
  const regex = /^[0-9]{1,2}$/;
  return regex.test(age);
};

const isValidPhoneNumber = (number) => {
  const regex = /^[0-9+]{11}$/;
  return regex.test(number);
};

const isValidDomain = (domain) => {
  const regex = /^@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(domain);
};

const isValidMongoId = (id) => {
  const regex = /^[a-f\d]{24}$/i;
  return regex.test(id);
};

const isAlphaNumeric = (str) => {
  const regex = /^[a-zA-Z0-9 ]{1,300}$/;
  return regex.test(str);
};

const isAlphaNumericWithSpecialChars = (str) => {
  const regex = /^[a-zA-Z0-9!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/? ]{1,200}$/;
  return regex.test(str);
};

const onlyNumbers = (str) => {
  const regex = /^[0-9]{1,200}$/;
  return regex.test(str);
};

const onlyChars = (str) => {
  const regex = /[^]+$/;
  return regex.test(str);
};

const containsOnlyNumbers = (str) => {
  // console.log([...str]);
  // return [...str].every((c) => '0123456789'.includes(c));
  return /[0-9]*/.test(str);
};

export const ticketTemplateRegex = {
  onlyNumbers,
  onlyChars,
};


// Regex Get
const EmailRegex = () => {
  return '.+@.+';
};

const NumbersRegex = () => {
  return `[0-9]{1,${maxChar}}`;
};

const TextRegex = () => {
  return '[^]+';
};

const AlphaNumericRegex = () => {
  return `[a-zA-Z0-9 ]{1,${maxChar}}`;
};

const AlphaNumericWithSpecialCharsRegex = () => {
  return '[a-zA-Z0-9!@#$%^&*()_+-=\\[]{};\':"\\|,.<>\\/? ]{1,300}';
};

const PhoneNumberRegex = () => {
  return '[0-9]{11}';
};

const CNICRegex = () => {
  return '[0-9]{13}';
};
export const appRegex = {
  EmailRegex: EmailRegex(),
  NumbersRegex: NumbersRegex(),
  TextRegex: TextRegex(),
  AlphaNumericRegex: AlphaNumericRegex(),
  AlphaNumericWithSpecialCharsRegex: AlphaNumericWithSpecialCharsRegex(),
  PhoneNumberRegex: PhoneNumberRegex(),
  CNICRegex: CNICRegex(),
};

// Combine all the regex functions in one object
export const regex = {
  isValidEmail,
  isValidCnic,
  isValidName,
  isValidAddress,
  isValidAge,
  isValidPhoneNumber,
  isValidDomain,
  isValidMongoId,
  isAlphaNumeric,
  isAlphaNumericWithSpecialChars,
  containsOnlyNumbers,
};

export {
  isValidEmail,
  isValidCnic,
  isValidName,
  isValidAddress,
  isValidAge,
  isValidPhoneNumber,
  isValidDomain,
  isValidMongoId,
};
