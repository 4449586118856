import { useRef } from 'react';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { IoIosArrowDown } from 'react-icons/io';

const Popup = ({
  children,
  title,
  isVisible,
  setVisibility,
  renderContent,
  className,
}) => {
  const popupRef = useRef();
  useOutsideClick(popupRef, () => {
    if (setVisibility) setVisibility(false);
  });

  return (
    <div ref={popupRef} className='relative inline-block text-left z-40 w-full'>
      <div className={`${className} w-full`}>
        {renderContent
          ? renderContent()
          : title && (
              <button
                type='button'
                onClick={() => setVisibility(!isVisible)}
                className='inline-flex w-full justify-center items-center gap-x-1.5 rounded-full bg-main px-4 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-main'
              >
                {title}
                <IoIosArrowDown />
              </button>
            )}
      </div>
      {isVisible && children}
    </div>
  );
};

export default Popup;
