import { Route, Routes, useLocation } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import { lazy, useEffect } from 'react';
import RoutesPath from './types';
import { ToastContainer } from 'react-toastify';
import { EventSteps } from '../pages/Events/EventSteps';
import { Activities } from 'pages/Activities';
import { PublicRoutes } from './PublicRoutes';
import { CheckoutSuccess } from 'pages/Checkout/CheckoutSuccess';

const EventItemRevamp = lazy(() => import('pages/Events/EventItemRevamp'));
const CheckoutLoader = lazy(() => import('pages/Checkout/Loader'));
const Settings = lazy(() => import('pages/OrganizeEvents/Settings'));
const Onboarding = lazy(() => import('pages/Onboarding/Onboarding'));

const CreateCompetition = lazy(() =>
  import('pages/OrganizeCompetitions/CreateCompetition')
);
const RefundPolicy = lazy(() => import('../pages/About/RefundPolicy'));
const Home = lazy(() => import('../pages/Home/Home'));
const Authentication = lazy(() => import('../pages/Auth/Auth'));
const ForgotPassword = lazy(() => import('../pages/Auth/ForgotPassword'));
const ConfirmPayment = lazy(() => import('../pages/Events/ConfirmPayment'));
// const CreditCard = lazy(() => import("../pages/Events/CreditCard"));
const EventItem = lazy(() => import('../pages/Events/EventItem'));
const NotFound = lazy(() => import('../components/NotFound/NotFound'));
const ArticleLayout = lazy(() => import('../layouts/ArticleLayout'));
const PreviewTicketDetail = lazy(() =>
  import('../pages/Events/PreviewTicketDetail')
);
const PrivacyPolicy = lazy(() => import('../pages/About/PrivacyPolicy'));
const TermsCondition = lazy(() => import('../pages/About/TermsCondition'));
const Search = lazy(() => import('../pages/Search/Search'));
const ResetPassword = lazy(() => import('../pages/Auth/ResetPassword'));
const ActivityLists = lazy(() =>
  import('../pages/OrganizeEvents/Events/ActivityLists')
);
const OrganizerProfile = lazy(() => import('../pages/OrganizeEvents/Profile'));
const Dashboard = lazy(() => import('../pages/OrganizeEvents/Dashboard'));
const CreateEvent = lazy(() =>
  import('../pages/OrganizeEvents/Events/CreateEvent')
);
const ManageEvent = lazy(() =>
  import('../pages/OrganizeEvents/Events/ManageEvents')
);
const Finance = lazy(() => import('../pages/OrganizeEvents/Finance'));
const FinanceDetail = lazy(() =>
  import('../pages/OrganizeEvents/Finance/FinanceDetail')
);
const Marketing = lazy(() => import('../pages/OrganizeEvents/Marketing'));
const Validators = lazy(() => import('../pages/OrganizeEvents/Validators'));
const Profile = lazy(() => import('../pages/Profile/Profile'));
const UpdateEvent = lazy(() =>
  import('../pages/OrganizeEvents/Events/UpdateEvent')
);
const FAQs = lazy(() => import('../pages/About/FAQs'));
const Contact = lazy(() => import('../pages/About/Contact'));
const AppPrivacyPolicy = lazy(() => import('../pages/About/AppPrivacyPolicy'));

const AppRoutes = () => {
  // const [focusChanged, setFocusChanged] = React.useState(false);

  // const shouldRedirectToAuth = () => {
  //   if (nonAuthRoutes.includes(window.location.pathname)) return false;

  //   const urls = window.location.pathname.split('/');
  //   // Remove index where empty string from array
  //   urls.splice(0, 1);

  //   // Only 1 non-auth route has length 2, which is events/:id
  //   if (
  //     urls.length === 2 &&
  //     urls[0] === 'events' &&
  //     regex.isValidMongoId(urls[1])
  //   )
  //     return false;

  //   // All Other non-auth routes have length 1
  //   if (nonAuthRoutes.includes(urls[0])) return false;
  //   return getCookie('id_token') !== '' || true;
  // };

  // useEffect(() => {
  //   const tabChanged = async () => {
  //     if (shouldRedirectToAuth() === false) return;
  //     const isRefreshed = await getToken()
  //     if (!isRefreshed) {
  //       navigate(RoutesPath.auth, { replace: true });
  //     }
  //   };
  //   tabChanged();
  // }, [focusChanged]);

  // useEffect(() => {
  //   window.addEventListener('visibilitychange', async () => {
  //     setFocusChanged(!focusChanged);
  //   });

  //   return () => {
  //     window.removeEventListener('visibilitychange', async () => {
  //       setFocusChanged(!focusChanged);
  //     });
  //   };
  // }, [focusChanged]);

  // Removing logs print in production
  useEffect(() => {
    const isDebug = process.env.REACT_APP_IS_PROD === 'false';
    if (!isDebug) {
      const __no_op = function () {
        return;
      };

      console.log = __no_op;
      console.warn = __no_op;
      console.error = __no_op;
      console.info = __no_op;
    }
  }, []);

  const location = useLocation();

  async function invalidateCache() {
    if ('caches' in window) {
      console.log(await caches.keys());
    }
  }

  useEffect(() => {
    invalidateCache();
  }, []);

  return (
    <>
      <ToastContainer />
      <Routes location={location} key={location.pathname}>
        <Route path={RoutesPath.onboarding} element={<Onboarding />} />
        <Route path={RoutesPath.home} element={<Home />} />
        <Route path={RoutesPath.auth} element={<Authentication />} />
        <Route element={<PublicRoutes />}>
          <Route path={RoutesPath.profile} element={<Profile />} />
          <Route path={RoutesPath.search} element={<Search />} />
          <Route
            path={RoutesPath.forgotPassword}
            element={<ForgotPassword />}
          />
          <Route path={RoutesPath.resetPassword} element={<ResetPassword />} />
          {/* <Route path={RoutesPath.eventItem} element={<EventItem />} /> */}
          <Route path={RoutesPath.eventItem} element={<EventItemRevamp />} />
          <Route path={RoutesPath.privacyPolicy} element={<PrivacyPolicy />} />
          <Route
            path={RoutesPath.appPrivacyPolicy}
            element={<AppPrivacyPolicy />}
          />
          <Route
            path={RoutesPath.termsAndConditions}
            element={<TermsCondition />}
          />
          <Route path={RoutesPath.faqs} element={<FAQs />} />
          <Route path={RoutesPath.contact} element={<Contact />} />
          <Route path={RoutesPath.refundPolicy} element={<RefundPolicy />} />
          <Route
            path={RoutesPath.checkoutSuccess}
            element={<CheckoutSuccess />}
          />

          <Route element={<PrivateRoutes />}>
            <Route
              path={RoutesPath.organizeEvent + RoutesPath.dashboard}
              element={<Dashboard />}
            />
            <Route
              path={RoutesPath.organizeEvent + RoutesPath.createCompetition}
              element={<CreateCompetition />}
            />
            <Route
              path={RoutesPath.organizeEvent + RoutesPath.activityLists}
              element={<ActivityLists />}
            />
            <Route
              path={RoutesPath.organizeEvent + RoutesPath.settings}
              element={<Settings />}
            />
            <Route path={RoutesPath.activities} element={<Activities />} />
            <Route
              path={RoutesPath.organizeEvent + RoutesPath.finance}
              element={<Finance />}
            />
            <Route
              path={RoutesPath.organizeEvent + RoutesPath.marketing}
              element={<Marketing />}
            />
            <Route
              path={RoutesPath.organizeEvent + RoutesPath.validators}
              element={<Validators />}
            />
            <Route
              path={RoutesPath.organizeEvent + RoutesPath.organizerProfile}
              element={<OrganizerProfile />}
            />
            <Route
              path={RoutesPath.organizeEvent + RoutesPath.createEvent}
              element={<CreateEvent />}
            />
            <Route
              path={`${RoutesPath.eventItem}${RoutesPath.buyTicket}${RoutesPath.creditCard}`}
              element={<PreviewTicketDetail />}
            />
            <Route
              path={`${RoutesPath.eventItem}${RoutesPath.buyTicket}${RoutesPath.creditCard}${RoutesPath.confirmPayment}`}
              element={<ConfirmPayment />}
            />
            <Route
              path={`${RoutesPath.organizeEvent}${RoutesPath.manageEventItem}`}
              element={<ManageEvent />}
            />
            <Route
              path={`${RoutesPath.organizeEvent}${RoutesPath.manageEventItem}${RoutesPath.updateEvent}`}
              element={<UpdateEvent />}
            />
            <Route
              path={`${RoutesPath.organizeEvent}${RoutesPath.financeEventItem}`}
              element={<FinanceDetail />}
            />
            <Route
              path={`${RoutesPath.eventItem}${RoutesPath.eventSteps}`}
              element={<EventSteps />}
            />
            <Route
              path={`${RoutesPath.checkout}`}
              element={<CheckoutLoader />}
            />
          </Route>
        </Route>

        <Route
          path='*'
          element={
            <ArticleLayout>
              <NotFound />
            </ArticleLayout>
          }
        />
      </Routes>
    </>
  );
};

export default AppRoutes;

window.recaptchaOptions = {
  useRecaptchaNet: true,
};
