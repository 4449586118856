const RoutesPath = Object.freeze({
  home: '/',
  auth: '/auth',
  profile: '/profile',
  search: '/search',
  eventItem: '/events/:id',
  organizeEvent: '/organize-event',
  dashboard: '/dashboard',
  marketing: '/marketing',
  finance: '/finance',
  validators: '/validators',
  createEvent: '/create-event',
  updateEvent: '/update-event',
  buyTicket: '/buy-ticket',
  creditCard: '/credit-card',
  confirmPayment: '/confirm-payment',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',
  manageEvent: '/manage-event',
  manageEventItem: '/manage-event/:id',
  financeEvent: '/finance-event',
  financeEventItem: '/finance-event/:id',
  ticketPaymentDetail: '/ticket-payment-detail',
  privacyPolicy: '/privacy-policy',
  appPrivacyPolicy: '/app-privacy-policy',
  termsAndConditions: '/terms-and-conditions',
  faqs: '/faqs',
  contact: '/contact',
  refundPolicy: '/refund-policy',
  activityLists: '/activity-lists',
  organizerProfile: '/organizer-profile',
  eventSteps: '/event-steps',

  activities: '/activities',

  createCompetition: '/create-competition',

  onboarding: '/onboarding',
  
  checkout: '/:paymentMethod/checkout',
  checkoutSuccess: '/checkout/:paymentMethod',

  settings: '/settings',

  revamp: '/revamp/:id',
});

export const nonAuthRoutes = [
  RoutesPath.home,
  RoutesPath.auth,
  RoutesPath.search,
  RoutesPath.forgotPassword,
  RoutesPath.resetPassword,
  RoutesPath.eventItem,
  RoutesPath.privacyPolicy,
  RoutesPath.appPrivacyPolicy,
  RoutesPath.termsAndConditions,
  RoutesPath.faqs,
  RoutesPath.contact,
];

export default RoutesPath;
