import event1 from '../assets/events/1.jpg';
import event2 from '../assets/events/2.jpg';
import event3 from '../assets/events/3.jpg';
import event4 from '../assets/events/4.jpg';

const EventsData = [
  {
    id: 1,
    name: 'Softec',
    month: 'MAY',
    date: '12',
    image: event1,
    location: 'Faisal Town',
    time: 'Friday, April 21, 2023 at 7:00 PKT',
    type: 'Approved',
  },
  {
    id: 2,
    name: 'Nascon',
    month: 'MAR',
    date: '6',
    image: event2,
    guard: 'yellow',
    location: 'Islamabad',
    time: 'Friday, April 21, 2023 at 7:00 PKT',
    type: 'Publish'
  },
  {
    id: 3,
    name: 'DAIRA',
    month: 'APR',
    date: '14',
    image: event3,
    guard: 'green',
    location: 'Faisal Town',
    time: 'Friday, April 21, 2023 at 7:00 PKT',
    type: 'Pending'
  },
  {
    id: 4,
    name: 'HOMECOMING',
    month: 'JUN',
    date: '3',
    image: event4,
    location: 'Faisal Town',
    time: 'Friday, April 21, 2023 at 7:00 PKT',
    type: 'Rejected'
  },
];


const StatusBgColor = {
  'Approved': 'bg-main',
  'Rejected': 'bg-rose-600',
  'Pending': 'bg-gray-500',
  'Published': 'bg-lime-500',
  'Delay': 'bg-orange-600',
  'Cancelled': 'bg-yellow-500',
  'Finished': 'bg-green-500',

  'APPROVED': 'bg-main',
  'REJECTED': 'bg-rose-600',
  'PENDING': 'bg-gray-500',
  'PUBLISHED': 'bg-lime-500',
  'DELAYED': 'bg-orange-600',
  'CANCELLED': 'bg-yellow-500',
  'FINISHED': 'bg-green-500',
};

const StatusTextColor = {
  'Approved': 'text-main',
  'Rejected': 'text-rose-600',
  'Pending': 'text-gray-500',
  'Published': 'text-lime-500',
  'Delay': 'text-orange-600',
  'Cancelled': 'text-yellow-500',
  'Finished': 'text-green-500',

  'APPROVED': 'text-main',
  'REJECTED': 'text-rose-600',
  'PENDING': 'text-gray-500',
  'PUBLISHED': 'text-lime-500',
  'DELAYED': 'text-orange-600',
  'CANCELLED': 'text-yellow-500',
  'FINISHED': 'text-green-500',
};

export { EventsData, StatusBgColor, StatusTextColor };
