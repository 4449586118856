import { useMemo } from 'react';

interface SkeletonProps {
    variant?: 'line' | 'block';
    count?: number;
    direction?: 'row' | 'col';
}

const Skeleton: React.FC<SkeletonProps> = (props) => {
    const {
        variant = 'line',
        count = 1,
        direction = 'col'
    } = props;

    const heightClass = useMemo(() => variant === 'line' ? 'h-4' : 'h-16', [variant]);
    const directionClass = useMemo(() => direction === 'col' ? 'flex-col' : '', [direction]);

    return (
        <div className={`flex w-full gap-4 ${directionClass} animate-pulse`}>
            {[...Array(count)].map((_e, i) => (
                <div key={i} className={`bg-gray-200 rounded-md dark:bg-gray-700 ${heightClass}`}></div>    
            ))}
        </div>
    );
};

export default Skeleton;
