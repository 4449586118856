import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PaymentSuccessfulAnimation from 'assets/animations/successful.json';
import ConfettiAnimation from 'assets/animations/confetti.json';
import LottieAnimation from 'components/LottieAnimation/LottieAnimation';
import PaymentErrorAnimation from 'assets/animations/ErrorPayment.json';
import PaymentPendingAnimation from 'assets/animations/PendingPayment.json';
import RoutesPath from 'routes/types';

export const CheckoutSuccess: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);

  const status =
    (queryParams.get('status') as 'success' | 'error' | 'pending') || 'success';
  const error = queryParams.get('error');

  const statusMessages = {
    success: {
      header: 'Thank you for your purchase.',
      message:
        'Check your email for the ticket. You will receive an email with the ticket shortly. You can also see your bought ticket in the profile section.',
      footer: 'Thank you for being part of this wondrous journey!',
      animation: PaymentSuccessfulAnimation,
    },
    error: {
      header: 'Payment failed.',
      message: 'We are sorry, Please try again.',
      footer: 'If you continue to face issues, please contact us at ',
      animation: PaymentErrorAnimation,
    },
    pending: {
      header: 'Ticket Purchase is in Pending State.',
      message:
        'Your payment is in pending state. You will receive an email with the ticket shortly (~30 Min). You can also see your bought ticket in the profile section.',
      footer: 'If you continue to face issues, please contact us at ',
      animation: PaymentPendingAnimation,
    },
  };

  const handleConfettiAnimation = () => {
    // For first 2500ms, show confetti animation and then remove it
    setTimeout(() => {
      const confetti = document.getElementById('confetti');
      if (confetti) confetti.style.visibility = 'hidden';
    }, 4000);
  };

  useEffect(() => {
    if (status === 'success') {
      handleConfettiAnimation();
    } else {
      const confetti = document.getElementById('confetti');
      if (confetti) confetti.style.visibility = 'hidden';
    }
  }, []);

  return (
    <div className='flex flex-col items-center justify-center h-[100vh] w-[100vw] overflow-clip md:bg-gray-200 my-auto'>
      <div className='flex flex-col items-center md:bg-white justify-center md:max-w-4xl md:min-w-[56rem] gap-5 px-6 md:py-6 mx-auto md:shadow-[20px_20px_60px_#D9D9D9,_-20px_-20px_60px_#FFFFF] md:rounded-2xl h-fit'>
        <LottieAnimation
          animation={statusMessages[status].animation}
          width={80}
          height={80}
          title=''
          loop={false}
          textClass='am'
        />
        <h1 className='text-xl font-bold text-center'>
          {statusMessages[status].header}
        </h1>
        <p className='text-base text-center'>
          {statusMessages[status].message}
        </p>
        {status === 'error' && (
          <div className='flex flex-col items-center justify-center text-lg'>
            <span className='font-bold'>Reason</span>
            <span>{error}</span>
          </div>
        )}
        <p className='text-base text-center'>
          {statusMessages[status].footer}
          {status === 'error' ||
            (status === 'pending' && (
              <>
                <a
                  href='mailto:info@nobel10.com'
                  className='font-medium underline text-main'
                >
                  support
                </a>
                &nbsp;or&nbsp;
                <a
                  href='tel:+923158591518'
                  className='font-medium underline text-main'
                >
                  call us
                </a>
                .
              </>
            ))}
        </p>
        <div className='flex flex-col w-full gap-4'>
          <Link
            to={RoutesPath.home}
            className='w-full px-4 py-3 font-bold text-center text-white no-underline transition-colors duration-300 ease-in-out rounded-lg shadow-md bg-main hover:bg-main/95 hover:text-white hover:no-underline'
          >
            Continue Exploring
          </Link>

          <Link
            to={RoutesPath.profile}
            className='w-full px-4 py-3 font-bold text-center no-underline transition-colors duration-300 ease-in-out border-2 border-gray-300 rounded-lg shadow hover:no-underline hover:text-black'
          >
            Visit Profile
          </Link>
        </div>
      </div>

      <div id='confetti'>
        <LottieAnimation
          animation={ConfettiAnimation}
          className='absolute !w-[100vw] !h-[100vh] !overflow-clip bottom-0 z-0'
          textClass='am'
          title=''
          speed={2.5}
          loop={false}
        />
      </div>
    </div>
  );
};
