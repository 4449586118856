import { appRegex } from 'helper/regex';

export interface TicketTemplateOptions {
  name: string;
  type: 'int' | 'string';
  isRequired: boolean;
  isShareable: boolean;
  pattern: string;
  errorMessage: string;
}

export interface TicketTemplate {
  _id: string;
  ticketType: string;
  title: string;
  description: string;
  price: number;
  earlyBirdDiscount: number;
  purchasesLeft: number;
  purchasesAllowed: number;
  quantity: number;
  scansAllowed: number;
  isArchived: boolean;
  requireApproval: boolean;
  options: TicketTemplateOptions[];
}

export interface CustomerTicketEntry {
  name: string;
  value: string;
}

export interface CustomerTicket {
  couponCode: string;
  ticketTemplateId: string;
  customerTicketDetails: CustomerTicketEntry[];
}

export const TicketTemplateNameOption: TicketTemplateOptions = {
  name: 'name',
  type: 'string',
  isRequired: true,
  isShareable: false,
  pattern: appRegex.TextRegex,
  errorMessage: 'Name must be of 3-50 alphabets.',
};

export const TicketTemplateCNICOption: TicketTemplateOptions = {
  name: 'cnic',
  type: 'string',
  isRequired: true,
  isShareable: false,
  pattern: appRegex.CNICRegex,
  errorMessage: 'CNIC should be 13 digits without dashes'
};

export const TicketTemplateEmailOption: TicketTemplateOptions = {
  name: 'email',
  type: 'string',
  isRequired: true,
  isShareable: false,
  pattern: appRegex.EmailRegex,
  errorMessage: 'CNIC should be 13 digits without dashes'
};
