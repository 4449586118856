import { useEffect, useState, useCallback } from 'react';
import { Steps } from 'rsuite';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../layouts/ArticleLayout';
import BuyEventPass from './BuyEventPass';
import Checkout from 'pages/Checkout/Checkout';

export const EventSteps = () => {
  const location = useLocation();
  const [steps, setSteps] = useState(0);
  const navigate = useNavigate();

  const renderSteps = () => {
    switch (steps) {
      case 0:
        return (
          <BuyEventPass
            location={location}
            onProceed={nextStep}
            onBack={prevStep}
          />
        );
      case 1:
        return <Checkout />;
      default:
        return (
          <BuyEventPass
            location={location}
            onProceed={nextStep}
            onBack={prevStep}
          />
        );
    }
  };

  const onChange = (nextStep) => {
    if (nextStep < min) {
      // go to previous page
      window.history.back();
    }
    setSteps(nextStep < min ? min : nextStep > max ? max : nextStep);
  };

  const nextStep = useCallback(() => setSteps(steps + 1), [steps]);
  const prevStep = useCallback(() => setSteps(steps - 1), [steps]);

  useEffect(() => {
    document.addEventListener(
      'keydown',
      (e) => {
        if (e.key === 'Backspace') {
          if (steps === 0) return;
          window.history.back();
        }
      },
      false
    );
    
    return () => {
      document.removeEventListener('keydown', (e) => {
        if (e.key === 'Backspace') {
          if (steps === 0) return;
          window.history.back();
        }
      });
    };
  }, []);

  const min = 0;
  const max = 3;

  return (
    <Layout footer={false}>
      <Steps
        current={steps}
        className='flex items-center justify-center w-full px-2 py-2 md:mx-auto md:w-1/2 md:px-0'
        onChange={onChange}
      >
        <Steps.Item title='Add Details' />
        <Steps.Item title='Checkout' />
      </Steps>
      {renderSteps()}
    </Layout>
  );
};
