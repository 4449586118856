import { images } from 'assets';

export interface PaymentMethod {
    id: string;
    code: PaymentMethodCodes;
    displayName: string;
    isActive: boolean;
}

export enum PaymentMethodCodes {
    JAZZCASH = 'JAZZCASH',
    ALFALAH = 'ALFALAH',
}

export const PaymentMethodIcons: Record<PaymentMethodCodes, any> = {
    [PaymentMethodCodes.JAZZCASH]: images.logos.jazzcash,
    // [PaymentMethodCodes.JAZZCASH]: images.svg.creditCard,
    [PaymentMethodCodes.ALFALAH]: images.svg.creditCard,
};

export interface JazzCashOrderRequest {
    pp_SecureHash: string;
    pp_Amount: string;
    pp_BillReference: string;
    pp_CustomerEmail: string;
    pp_CustomerID: string;
    pp_CustomerMobile: '';
    pp_Description: string;
    pp_IsRegisteredCustomer: 'Yes' | 'No';
    pp_Language: 'EN';
    pp_MerchantID: string;
    pp_Password: string;
    pp_ReturnURL: string;
    pp_SubMerchantID: '';
    pp_TokenizedCardNumber: '';
    pp_TxnCurrency: 'PKR';
    pp_TxnDateTime: string;
    pp_TxnExpiryDateTime: string;
    pp_TxnRefNo: string;
    pp_TxnType: 'MPAY' | 'MWALLET' | 'OTC' | '';
    pp_Version: '2.0';
    ppmpf_1: string;
    ppmpf_2: string;
    ppmpf_3: '3';
    ppmpf_4: '4';
    ppmpf_5: '5';
}

export interface AlafalahOrderRequest {
    AuthToken: string;
    RequestHash: string;
    ChannelId: '1001';
    Currency: 'PKR';
    ReturnURL: string;
    MerchantId: string;
    StoreId: string;
    MerchantHash: string;
    MerchantUsername: string;
    MerchantPassword: string;
    TransactionTypeId: '1' | '2' | '3';
    TransactionReferenceNumber: string;
    TransactionAmount: string;
}

export interface IJazzCashMobileOrderRequest {

    pp_Language: 'EN';
    pp_MerchantID: string;
    pp_SubMerchantID: string;
    pp_Password: string;
    pp_BankID: string;
    pp_ProductID: string;
    pp_TxnRefNo: string;
    pp_Amount: string;
    pp_TxnCurrency: 'PKR';
    pp_TxnDateTime: string;
    pp_BillReference: string;
    pp_Description: string;
    pp_TxnExpiryDateTime: string;
    pp_SecureHash: string;
    ppmpf_1: string;
    ppmpf_2: string;
    ppmpf_3: string;
    ppmpf_4: '4';
    ppmpf_5: '5';
    pp_MobileNumber: string;
    pp_CNIC: string;
}

export interface IJazzCashMobileOrderResponse {
    pp_TxnType: string;
    pp_Version: number;
    pp_Amount: string; //'105000',
    pp_AuthCode: string; //'',
    pp_BillReference: string; //'billRef',
    pp_Language: string; //'EN',
    pp_MerchantID: string; //'MC54504',
    pp_ResponseCode: string; //'000',
    pp_ResponseMessage: string; //'Thank you for Using JazzCash, your transaction was successful.',
    pp_RetreivalReferenceNo: string; //'230509347867',
    pp_SubMerchantId: string; //'',
    pp_TxnCurrency: string; //'PKR',
    pp_TxnDateTime: string; // '20230508232607',
    pp_TxnRefNo: string; //'T20230508232607'
    pp_MobileNumber: string;
    pp_CNIC: string;
    pp_DiscountedAmount: string;
    ppmpf_1: string;
    ppmpf_2: string;
    ppmpf_3: string;
    ppmpf_4: string;
    ppmpf_5: string;
    pp_SecureHash: string;
}
