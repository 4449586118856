import { Id, ToastOptions, toast } from 'react-toastify';

export class Toast {
  static toastId: Id;

  static isActive = () => {
    return toast.isActive(Toast.toastId);
  };

  static success(message: string, options?: ToastOptions) {
    if (Toast.isActive()) return;
    Toast.toastId = toast.success(message, {
      position: options?.position || toast.POSITION.TOP_RIGHT,
      autoClose: options?.autoClose || 1500,
    });
  }

  static error(message: string, options?: ToastOptions) {
    if (Toast.isActive()) return;
    Toast.toastId = toast.error(message, {
      position: options?.position || toast.POSITION.TOP_RIGHT,
      autoClose: options?.autoClose || 1500,
    });
  }

  static warning(message: string, options?: ToastOptions) {
    if (Toast.isActive()) return;
    Toast.toastId = toast.warning(message, {
      position: options?.position || toast.POSITION.TOP_RIGHT,
      autoClose: options?.autoClose || 1500,
    });
  }

  static info(message: string, options?: ToastOptions) {
    if (Toast.isActive()) return;
    Toast.toastId = toast.info(message, {
      position: options?.position || toast.POSITION.TOP_RIGHT,
      autoClose: options?.autoClose || 1500,
    });
  }
}
