import Skeleton from 'components/UI/Skeleton/Skeleton';
import { getDayAndMonth } from '../../helper/date';
import { useMemo, useState } from 'react';
import { SlLocationPin } from 'react-icons/sl';

interface EventCardSmallProps {
  event: any;
  loading?: boolean;
  hover?: boolean;
  variant?: 'card' | 'outline';
  showLocation?: boolean;
}

const EventCardSmall: React.FC<EventCardSmallProps> = (props) => {
  const {
    event,
    loading,
    hover,
    variant = 'card',
    showLocation = false,
  } = props;
  //useLazyLoad();
  const [imgLoaded, setImgLoaded] = useState<boolean>(event? true: false);
  const frameStyle = useMemo(() => variant === 'card' ?  'bg-white shadow-md' : 'bg-gray-100 border border-gray-300', [variant]);

  return (
    <div className={`min-w-[20rem] h-28 flex rounded-xl overflow-hidden transition-all blur-load ${frameStyle} ${hover ? 'hover:scale-105 ' : ''}`}>
      <div className="h-full w-40 flex">
      {!loading && <img
          src={event?.picture}
          className={`h-full w-full object-cover ${!imgLoaded ? 'hidden' : ''}`}
          alt={event?.name}
          loading="lazy"
          onLoad={() => setImgLoaded(true)}
        />}
        {loading || !imgLoaded && 
          <div className='flex items-center w-full ml-2'>
            <Skeleton variant='block' />
          </div>
        }
      </div>
      {!loading && <div className="p-4 flex flex-col flex-1">
        <div className="font-semibold text-dark text-md text-ellipsis overflow-hidden">
          {event?.name}
        </div>
        <p className="text-main text-md font-semibold">
          {getDayAndMonth(event?.from)}
          <span className="text-gray-700">{' - '}</span>
          {getDayAndMonth(event?.to)}
        </p>
        {showLocation && <div className="mt-auto text-md font-medium flex items-center gap-1">
            <SlLocationPin size={16} />
            <span>
              {event?.site?.location}
            </span>
          </div>}
      </div>}
      {loading && <div className='flex flex-1 p-4 items-center'>
        <Skeleton count={3} />
      </div>}
    </div>
  );
};

export default EventCardSmall;
