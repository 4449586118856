interface TicketCardListProps {
  className?: string;
}

const TicketCardList: React.FC<React.PropsWithChildren<TicketCardListProps>> = ({ className, children }) => {
  return (
    <div className={`bg-neutral-100 md:h-80 md:overflow-y-auto p-4 rounded-lg flex flex-col gap-4 border border-neutral-200 ${className}`}>
        {children}
    </div>
  );
};

export default TicketCardList;
