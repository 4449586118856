import React, { useEffect, useState } from 'react';
import { Competition, CompetitionResponse } from 'types/Competition';
import { Toast } from 'helper/toast';
import { useUser } from 'context/UserContext';
import { UserContext } from 'types/useUser';
import { FaSearch, FaTicketAlt } from 'react-icons/fa';
import { Table } from 'rsuite';
import { Link } from 'react-router-dom';
import RoutesPath from 'routes/types';
import LottieAnimation from 'components/LottieAnimation/LottieAnimation';
import ghost from 'assets/animations/buy.json';

export const Competitions: React.FC = () => {
  const [competitions, setCompetitions] = useState<Competition[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const { API } = useUser() as UserContext;

  const searchEvents = (searchText: string): Competition[] => {
    if (searchText === '') return competitions;
    return competitions.filter((competition) =>
      competition.name.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  useEffect(() => {
    const fetchCompetitions = async (): Promise<void> => {
      try {
        const res = await API.get<CompetitionResponse>('/competitions/O');
        const { dto } = res.data;
        setCompetitions(dto);
      } catch (err) {
        Toast.error(err as string);
      }
    };
    fetchCompetitions();
  }, []);

  return <LottieAnimation animation={ghost} width={250} height={250} />;

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-col justify-between gap-4 md:flex-row'>
        <div className='flex w-full gap-4'>
          <div className='flex items-center w-full px-2 py-3 space-x-2 bg-gray-100 border rounded focus:outline-none focus:border-2 focus:border-main'>
            <FaSearch size={20} className='text-main' />
            <input
              className='w-full bg-transparent focus:outline-none'
              type='text'
              placeholder='Search'
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <div className='flex justify-end w-full gap-4'>
          <Link
            to={RoutesPath.organizeEvent + RoutesPath.createCompetition}
            className='flex items-center gap-2 px-6 py-2 font-semibold border-2 rounded hover:no-underline md:text-lg border-main text-main'
          >
            <FaTicketAlt size={18} className='hidden sm:block' />
            Create Competition
          </Link>
        </div>
      </div>
      <Table
        virtualized
        className='overflow-y-auto rounded shadow-all'
        rowHeight={60}
        data={searchEvents(searchText)}
        id='table'
        height={300}
      >
        <Table.Column width={250} verticalAlign='middle' resizable>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.Cell dataKey='name' />
        </Table.Column>

        <Table.Column width={250} resizable>
          <Table.HeaderCell>Location</Table.HeaderCell>
          <Table.Cell dataKey='location' />
        </Table.Column>

        <Table.Column width={250} resizable>
          <Table.HeaderCell>Date - Time</Table.HeaderCell>
          <Table.Cell dataKey='date' />
        </Table.Column>

        <Table.Column width={250} resizable>
          <Table.HeaderCell>Competition URL</Table.HeaderCell>
          <Table.Cell dataKey='url' />
        </Table.Column>

        <Table.Column width={250} resizable>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.Cell dataKey='status' />
        </Table.Column>
      </Table>
    </div>
  );
};
